import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg/svg-decorator-blob-7.svg";
import Noimg from "../images/no_img.jpg";
import { getProduct, orderStatus } from "api/ProductApi";
import { currentRegister, filterFormatToDate } from "utility/Constant.js";
import { Link, useHistory, useLocation } from "react-router-dom";
import ProductCommon from "../components/ProductLike&Wish";
import Skeleton from "react-loading-skeleton";
import { getBranchId } from "utility/HeaderConfig";
import { getPageProductType } from "api/ProductTypeApi";
import { getAllProductSubCate } from "api/ProductSubCateApi";
import { getAllProductCate } from "api/ProductCategoryApi";
import { genderBasedIcons } from "components/GenderBasedIcons";
import no_data from "images/no_data.png";
import SkeletonLoader from "components/SkeletonLoader";
import { dayClose } from "../api/NewSchemeApi";
import moment from "moment";

const Container = tw.div`relative overflow-hidden`;
const TabContent = tw(motion.div)`block `;
const CardContainer = tw.div`mt-4 w-full relative`;
const Card = tw(
  motion.div
)`bg-white block mx-auto  sm:mx-0 no-underline hover:shadow-2xl rounded-lg duration-500 cursor-pointer `;
const CardImageContainer = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`bg-center bg-cover relative h-80 border border-gray-400 border-solid rounded-t-lg `}
`;
const CardText = tw.div`p-4 pb-1 text-gray-900 bg-gray-200 border border-gray-400 border-solid rounded-b-lg`;
const CardTitle = tw.h5`text-sm group-hover:text-yellow-600 no-underline  text-gray-600 lowercase`;
const CardContent = tw.p`mt-1 mb-2 text-sm font-medium text-custom-100  uppercase`;
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-custom-100`}
`;

export default ({ configImages }) => {
  /*
   * To customize the tabs, pass in data using the `tabs` prop. It should be an object which contains the name of the tab
   * as the key and value of the key will be its content (as an array of objects).
   * To see what attributes are configurable of each object inside this array see the example above for "Starters".
   */
  const location = useLocation();
  const myparam = location.state;

  const [banner, setBanner] = useState([]);
  const [loading, setLoading] = useState(true);
  const [listLoading, setListLoading] = useState(true);
  const [size, setSize] = useState(21);
  const [productType, setProductType] = useState([]);
  const [productSubCate, setProductSubCate] = useState([]);
  const [productCategory, setCategoryList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [dayClosedDates, setDayClosedDates] = useState("");
  const [orderStatusList, setOrderStatusList] = useState("");
  const [selectedList, setSelectedList] = useState({
    type: "",
    category: "",
    subCategory: "",
    gender: "",
  });
  //eslint-disable-next-line
  const [lists,setLists] = useState([
    "gold","silver","diamond","platinum"
  ])

  useEffect(()=>{
    if(dayClosedDates !==""){
      getProductList(dayClosedDates);
    }else{
      dayClose({
        module: "Sales",
        branchId: getBranchId(),
      }).then((data) => {
        if(data.response.length > 0){
          let dateString = data.response[0].activeDate;
          let date = moment(dateString, "MMM DD, YYYY, h:mm:ss A");
          let dayCloseDate = date.format('YYYY-MM-DD');
          getProductList(dayCloseDate)
        }   
      });
    }
    //eslint-disable-next-line
  },[orderStatusList])

  useEffect(() => {
    getProductType()
    setSelectedList({
      ...selectedList,
      type: myparam && myparam.type ? myparam.type.id : "",
      category:
        myparam && myparam.category
          ? myparam.category.id
          : selectedList.category,
      subCategory: "",
      typeName: myparam && myparam.type ? myparam.type.name : "",
      categoryName:
        myparam && myparam.category
          ? myparam.category.categoryName
          : selectedList.categoryName,
      subCategoryName: "",
      genderName: "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myparam]);


  useEffect(() => {
    Promise.all([
    getOrderStatusSale(),
    getOrderStatusTransfer(),
    getOrderStatusSold()
  ]).then((result) => {
    const [orderSaleStatus, orderTransferStatus, orderSoldStatus] = result;
    // const list = []
    // list.push(orderSaleStatus, orderTransferStatus, orderSoldStatus)
    if(myparam?.category){
      getProducts(orderSaleStatus, orderTransferStatus, orderSoldStatus)
      setSelectedList({
        ...selectedList,
        type: myparam && myparam.category.metaProdType ? myparam.category.metaProdTypeId : "",
        category: myparam && myparam.category.categoryName? myparam.category.id : selectedList.category,
        subCategory: "",
        typeName: myparam && myparam.category.metaProdType ? myparam.category.metaProdType : "",
        categoryName: myparam && myparam.category.categoryName ? myparam.categoryName: selectedList.category,
        subCategoryName: "",
        genderName: "",
      });     
      }
      if(myparam?.type){
        setSelectedList({
          ...selectedList,
          type: myparam.type.id,
          category: "",
          subCategory: "",
          typeName: myparam.type.name,
          categoryName: "",
          subCategoryName: "",
        });
      }
      setOrderStatusList(prevState => [...prevState, orderSaleStatus, orderTransferStatus, orderSoldStatus]);
    })
    //eslint-disable-next-line
  }, []);
  
  
    const getProducts =(orderSaleStatus, orderTransferStatus, orderSoldStatus) =>{
    let filterData ={
      allowCatalogue: 1,
      beforeCreatedAt: currentRegister(),
      demoStatus: "Demo_Completed,Demo_Cancelled,Available_For_Demo",
      metaProdCateEnty: myparam && myparam.category.categoryName? myparam.category.id : selectedList.category,
      metaProdTypeEnty: myparam && myparam.category.metaProdType ? myparam.category.metaProdTypeId : '',
      removeHp: "HP-",
      removeNTP: "NTP-",
      sortType: true,
      statusId: `${orderSaleStatus}, ${orderTransferStatus}, ${orderSoldStatus}`,
      sort: "asc",
    }
    getProduct(size, filterData)
    .then((data) => {
      setProductList(data.response.content);
      setLoading(false);
    })
    .catch((err) => {
      console.log(err);
    });
    }
    
    
    const getOrderStatusSale = () => {
    return orderStatus({ orderStatusName: "sale" })
      .then((data) => {
        return data.response[0].id;
      })
      .catch((err) => console.error(err));
  };

  const getOrderStatusTransfer = () => {
    return orderStatus({ orderStatusName: "Transferred" })
      .then((data) => {
        return data.response[0].id;
      })
      .catch((err) => console.error(err));
  };

  const getOrderStatusSold = () => {
    return orderStatus({ orderStatusName: "Sold" })
      .then((data) => {
        return data.response[0].id;
      })
      .catch((err) => console.error(err));
  };


  useEffect(() => {
    if (
      selectedList.type ||
      selectedList.category ||
      selectedList.subCategory ||
      selectedList.gender ||
      selectedList.typeName === "" ||
      selectedList.categoryName === "" ||
      selectedList.subCategoryName === "" ||
      selectedList.genderName === ""
    ) {
      // dayCloseDay();
      dayClose({
        module: "Sales",
        branchId: getBranchId(),
      }).then((data) => {
        if(data.response.length > 0){
          let dateString = data.response[0].activeDate;
          let date = moment(dateString, "MMM DD, YYYY, h:mm:ss A");
          let dayCloseDate = date.format('YYYY-MM-DD');
          getProductList(dayCloseDate)
        }   
      });
    } else if (!myparam) {
      // dayCloseDay();
      dayClose({
        module: "Sales",
        branchId: getBranchId(),
      }).then((data) => {
        if(data.response.length > 0){
          let dateString = data.response[0].activeDate;
          let date = moment(dateString, "MMM DD, YYYY, h:mm:ss A");
          let dayCloseDate = date.format('YYYY-MM-DD');
          getProductList(dayCloseDate)
        }   
      });
    }
    //eslint-disable-next-line
  }, [selectedList, size]);

  const history = useHistory();

  const getProductType = () => {
    let pList = [];
    setListLoading(true);
    getPageProductType({active:1}).then((data) => {
      setListLoading(true);
      let response = data.response.content;
       pList = response.filter((data) => data.metaProductCategoryList.length > 0 && data.id )
      //   if (data.metaProductCategoryList.length > 0) {
      //     const TypeName = data.name.toLowerCase().trim();
      //     return (
      //       TypeName === "gold" ||
      //       TypeName === "silver" ||
      //       TypeName === "silver articles" ||
      //       TypeName === "gift articles" ||
      //       TypeName === "diamond" ||
      //       TypeName === "platinum"
      //     );
      //   } else {
      //     return false;
      //   }
      // });
      pList.reverse();
      setProductType(pList);
      setListLoading(false);
      // setCategoryList(response.metaProductCategoryList);
    });
  };

  const getCategoryList = () => {
    setListLoading(true);
    let visibilityList = [];
    let filterData = {
      metaProdTypeEnty: selectedList.type ? selectedList.type : "",
      // visibilityMode: "WEB_MOBILE,MOBILE",
    };
    if (filterData.metaProdTypeEnty === "") {
      delete filterData.metaProdTypeEnty;
    }
    getAllProductCate(filterData).then((data) => {
      const productResponse = data.response;
      visibilityList = productResponse.filter(item => item.visibilityMode === 'WEB_MOBILE' || item.visibilityMode === 'MOBILE')
      setCategoryList(visibilityList);
      setListLoading(false);
    });
  };

  const getProductSub = () => {
    setListLoading(true);
    let filterData = {
      metaProdTypeEnty: selectedList.type ? selectedList.type : "",
      metaProdCateEnty: selectedList.category ? selectedList.category : "",
    };
    if (filterData.metaProdTypeEnty === "") {
      delete filterData.metaProdTypeEnty;
    }
    if (filterData.metaProdCateEnty === "") {
      delete filterData.metaProdCateEnty;
    }
    getAllProductSubCate(filterData).then((data) => {
      let response = data.response;
      setProductSubCate(response);
      setListLoading(false);
    });
  };

  const handleChange = (e) => {
    let target = e.target;
    let input = target.name;
    let value = target.value;
    var index = e.nativeEvent.target.selectedIndex;
    let text = e.nativeEvent.target[index].text;
    if (input === "type") {
      setSelectedList({
        ...selectedList,
        type: value,
        category: "",
        subCategory: "",
        typeName: text,
        categoryName: "",
        subCategoryName: "",
      });
    } else if (input === "category") {
      setSelectedList({
        ...selectedList,
        category: value,
        subCategory: "",
        categoryName: text,
        subCategoryName: "",
      });
    } else if (input === "subCategory") {
      setSelectedList({
        ...selectedList,
        subCategory: value,
        subCategoryName: text,
      });
    } else if (input === "gender") {
      setSelectedList({
        ...selectedList,
        gender: value,
        genderName: text,
      });
    }
  };

  //eslint-disable-next-line
  const dayCloseDay = () => {
    dayClose({
      module: "Sales",
      branchId: getBranchId(),
    }).then((data) => {
      if(data.response.length > 0){
        let dateString = data.response[0].activeDate;
        let date = moment(dateString, "MMM DD, YYYY, h:mm:ss A");
        let dayCloseDate = date.format('YYYY-MM-DD');
        setDayClosedDates(dayCloseDate);
      }   
    });
  };


  const getProductList = (dayCloseDate) => {
    setLoading(true);
    const[sale,delivered,sold] = orderStatusList
    let filterData = {
      removeNTP: "NTP-",
      removeHP: "HP-",
      beforeCreatedAt: filterFormatToDate(dayCloseDate),
      branch: getBranchId(),
      metaProdTypeEnty: selectedList.type ? selectedList.type : "",
      metaProdCateEnty: selectedList.category ? selectedList.category : "",
      productSubcategory: selectedList.subCategory
        ? selectedList.subCategory
        : "",
      userType: selectedList.gender ? selectedList.gender : "",
      statusId: `${sale},${delivered},${sold}`,
      allowCatalogue: 1,
      productDemoStatus: "Available_For_Demo,Demo_Completed,Demo_Cancelled",
    };
    if (filterData.metaProdTypeEnty === "") {
      delete filterData.metaProdTypeEnty;
    }
    if (filterData.metaProdCateEnty === "") {
      delete filterData.metaProdCateEnty;
    }
    if (filterData.productSubcategory === "") {
      delete filterData.productSubcategory;
    }
    if (filterData.userType === "") {
      delete filterData.userType;
    }
    if(sale !== undefined && delivered !== undefined && sold !== undefined && sale !== '' && delivered !== '' && sold !== '' ){
     const res = getProduct(size, filterData)
      res.then((data) => {
        // console.log(data.response);
        
        if(typeof data.response === "string"){
          let parsedResponse = JSON.parse(data.response);
          // console.log("parsedResponse",parsedResponse);          
          // setProductList(parsedResponse)
        }else{
          setProductList(data.response.content);
        }
        // if(data.response?.content){
        // }else{
          // const value = JSON.parse( data.response)
          // setProductList(value.content)
        // }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    };
  };

  const resetAll = () => {
    setSelectedList({
      type: "",
      category: "",
      subCategory: "",
      gender: "",
      typeName: "",
      categoryName: "",
      subCategoryName: "",
      genderName: "",
    });
  };

  useEffect(() => {
    getBanner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBanner = () => {
    configImages && configImages.E_COMMERCE_BANNERS.forEach((data) => {
      if (data.key === "productList") {
        setBanner(data.value);
      }
    });
  };

  return (
    <Container>
      {banner && banner.length > 0 ? (
        <img src={banner} alt="Jewellery Product List " className="w-100" />
      ) : (
        <Skeleton tw="w-full" style={{ height: "200px" }} />
      )}
      <div className="ptSans px-sm-5 px-0" tw="text-sm pt-3">
        <Link to="/" tw="mx-3 no-underline text-black">
          Home
        </Link>
        /
        <span tw="ml-3 text-custom-100 capitalize tracking-wide">
          {selectedList.typeName && selectedList.typeName
            ? selectedList.typeName
            : selectedList.categoryName && selectedList.categoryName}{" "}
        </span>
      </div>
      <div className="container pt-3 pt-sm-5 ptSans">
        <div className="row flex-wrap align-items-center">
          <div className="col-md-1 d-md-none  d-lg-none d-xl-block">
            <span>Filter By :</span>
          </div>
          <div className="mb-2 col-md-6 col-lg-3 py-sm-0 py-2">
            <select
              className="selectpicker form-select"
              id="style-11"
              tw="px-4 py-2 focus:outline-none focus:border-custom-100 focus:ring-1 uppercase"
              onFocus={getProductType}
              name="type"
              onChange={handleChange}
              value={selectedList.type}
            >
              <option hidden className="text-uppercase">
                METAL TYPE
              </option>
              {productType.length === 0  ? (
                <option className="text-lowercase" tw="text-lg">
                  No Data
                </option>
              ) : (
                productType.map((data) => (
                  <option key={data.id} value={data.id} tw="uppercase">
                    {data.name}
                  </option>
                ))
              )}
              {/* {listLoading && (
                <option className="text-lowercase">Loading...</option>
              )} */}
            </select>
          </div>
          <div className="mb-2 col-md-6 col-lg-3 py-sm-0 py-2">
            <select
              className="selectpicker form-select"
              tw="px-4 py-2 focus:outline-none focus:border-custom-100 focus:ring-1 uppercase"
              onFocus={getCategoryList}
              name="category"
              onChange={handleChange}
              id="style-11"
              value={selectedList.category}
            >
              <option hidden className="text-uppercase">
                PRODUCT CATEGORY
              </option>
              {productCategory.length === 0 && !listLoading ? (
                <option className="text-lowercase" tw="text-lg">
                  No Data
                </option>
              ) : (
                productCategory.map((data) => (
                  <option
                    value={data.id}
                    tw="uppercase"
                    className={`${listLoading && "d-none"}`}
                  >
                    {data.categoryName}
                  </option>
                ))
              )}
              {listLoading && (
                <option className="text-lowercase">loading...</option>
              )}
            </select>
          </div>
          <div className="mb-2 col-md-6 col-lg-3 py-sm-0 py-2">
            <select
              className="selectpicker form-select"
              tw="px-4 py-2 focus:outline-none focus:border-custom-100 focus:ring-1 uppercase"
              onFocus={getProductSub}
              name="subCategory"
              onChange={handleChange}
              id="style-11"
              value={selectedList.subCategory}
            >
              <option hidden className="text-uppercase">
                PRODUCT SUBCATEGORY
              </option>
              {productSubCate.length === 0 && !listLoading ? (
                <option className="text-lowercase" tw="text-lg">
                  No Data
                </option>
              ) : (
                productSubCate.map((data) => (
                  <option
                    value={data.id}
                    tw="uppercase"
                    className={`${listLoading && "d-none"}`}
                  >
                    {data.subCategoryName}
                  </option>
                ))
              )}
              {listLoading && (
                <option className="text-lowercase">loading...</option>
              )}
            </select>
          </div>
          <div className=" col-md-6 col-lg-3 col-xl-2  mb-2 py-sm-0 py-2">
            <select
              className="selectpicker form-select"
              tw="px-4 py-2 focus:outline-none focus:border-custom-100 focus:ring-1"
              name="gender"
              onChange={handleChange}
              id="style-11"
              value={selectedList.gender}
            >
              <option hidden className="text-uppercase">
                GENDER
              </option>
              <option className="my-2" value="A">
                ALL
              </option>
              {genderBasedIcons.map((data, index) => (
                <option className="my-2" key={index} value={data.gender}>
                  {data.title}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      {(selectedList.type ||
        selectedList.category ||
        selectedList.subCategory ||
        selectedList.gender) && (
        <div className="container ">
          <div className="px-sm-4 px-2 py-2 py-sm-3 mt-3 mx-0 border rounded row align-items-center w-100">
            <span className="ptSans col-md-3 col-lg-3 col-xl-2  px-md-2 d-none d-lg-block">
              Currently Shopping by :
            </span>
            <div className="d-flex justify-content-between align-items-center col-md-12 p-0 col-lg-9 col-xl-10">
              <ul className="d-flex align-items-center m-0 p-0 ptSans flex-wrap">
                {selectedList.typeName && (
                  <li className="item amshopby-item mx-2 mb-1">
                    <div
                      className="amshopby-filter-value text-black"
                      tw="tracking-wide uppercase"
                    >
                      {selectedList.typeName}
                    </div>
                    <span
                      className="amshopby-remove "
                      tw="cursor-pointer"
                      onClick={() =>
                        setSelectedList({
                          ...selectedList,
                          type: "",
                          typeName: "",
                        })
                      }
                    >
                      <i
                        className="bi bi-x-circle-fill ms-2 text-secondary"
                        tw="text-base"
                      ></i>
                    </span>
                  </li>
                )}
                {selectedList.categoryName && (
                  <li className="item amshopby-item mx-2 mb-1">
                    <div
                      className="amshopby-filter-value text-black"
                      tw="tracking-wide uppercase"
                    >
                      {selectedList.categoryName}
                    </div>
                    <span
                      className="amshopby-remove "
                      tw="cursor-pointer"
                      onClick={() =>
                        setSelectedList({
                          ...selectedList,
                          category: "",
                          categoryName: "",
                        })
                      }
                    >
                      <i
                        className="bi bi-x-circle-fill ms-2 text-secondary"
                        tw="text-base"
                      ></i>
                    </span>
                  </li>
                )}
                {selectedList.subCategoryName && (
                  <li className="item amshopby-item mx-2 mb-1">
                    <div
                      className="amshopby-filter-value text-black"
                      tw="tracking-wide uppercase"
                    >
                      {selectedList.subCategoryName}
                    </div>
                    <span
                      className="amshopby-remove "
                      tw="cursor-pointer"
                      onClick={() =>
                        setSelectedList({
                          ...selectedList,
                          subCategory: "",
                          subCategoryName: "",
                        })
                      }
                    >
                      <i
                        className="bi bi-x-circle-fill ms-2 text-secondary"
                        tw="text-base"
                      ></i>
                    </span>
                  </li>
                )}
                {selectedList.genderName && (
                  <li className="item amshopby-item mx-2 mb-1">
                    <div
                      className="amshopby-filter-value text-black"
                      tw="tracking-wide uppercase"
                    >
                      {selectedList.genderName}
                    </div>
                    <span
                      className="amshopby-remove "
                      tw="cursor-pointer"
                      onClick={() =>
                        setSelectedList({
                          ...selectedList,
                          gender: "",
                          genderName: "",
                        })
                      }
                    >
                      <i
                        className="bi bi-x-circle-fill ms-2 text-secondary"
                        tw="text-base"
                      ></i>
                    </span>
                  </li>
                )}
              </ul>
              <span
                tw="text-custom-100 sm:text-base text-sm  font-semibold  cursor-pointer text-center"
                onClick={resetAll}
              >
                Clear all
              </span>
            </div>
          </div>
        </div>
      )}
      <div className="pt-sm-3 pt-0 pb-5 mb-5 position-relative">
        <div className="container container-influid-md">
          {productList && productList.length === 0 && !loading ? (
            <img src={no_data} alt="no-data" className="col-md-5 m-auto" />
          ) : (
            <div className="row">
              {productList && productList.map((categoryItem, index) => (
                <TabContent
                  key={index}
                  transition={{ duration: 0.4 }}
                  className="col-md-6 col-lg-4 col-xl-3"
                >
                  <CardContainer>
                    <ProductCommon categoryItem={categoryItem} />
                    <Card
                      className="group"
                      initial="rest"
                      whileHover="hover"
                      animate="rest"
                      onClick={() =>
                        history.push("/ProductDetails", categoryItem)
                      }
                    >
                      <div className="overflow-hidden">
                        <CardImageContainer
                          imageSrc={
                            categoryItem.images === "@#@"
                              ? Noimg
                              : categoryItem.images.split("@#@")[0]
                          }
                          tw="transform transition duration-500  group-hover:(scale-125 rotate-12)"
                        ></CardImageContainer>
                        <div
                          className="badge"
                          tw="absolute  top-0 left-0 m-3 bg-yellow-600 p-1"
                        >
                          {categoryItem.stockStatus === "Sale" && "Available"}
                        </div>
                      </div>
                      <CardText>
                        <div className="d-flex justify-content-between mb-1 align-items-center">
                          <div className="col-md-7">
                            <CardTitle className="capitalize">
                              {categoryItem.metalType}
                            </CardTitle>
                            <CardContent
                              className="capitalize"
                              tw="truncate w-full "
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title={categoryItem.title}
                            >
                              {categoryItem.title}
                            </CardContent>
                          </div>
                          <div className="col-md-5 text-end">
                            <b tw="text-lg flex justify-end tracking-wide">
                              <span className="mx-1">&#8377;</span>{" "}
                              {Math.trunc(categoryItem.total)}
                            </b>
                            {categoryItem.goldPurity !== "-" && (
                              <small tw="text-xs">
                                {categoryItem.goldPurity}
                              </small>
                            )}
                          </div>
                        </div>
                      </CardText>
                    </Card>
                  </CardContainer>
                </TabContent>
              ))}
              <DecoratorBlob1 />
              <DecoratorBlob2 />
            </div>
          )}
          {loading && <SkeletonLoader />}
          <div className="d-flex justify-content-center pt-4 mt-3">
            {productList && productList.length !== 0 && (
              <div
                type="button"
                tw="border border-custom-100 duration-300 hover:text-white hover:bg-custom-100 px-20 py-2  text-sm"
                onClick={() => setSize(size + 3)}
              >
                {loading ? "Loading ..." : "View More"}
              </div>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};
