import { reactLocalStorage } from "reactjs-localstorage";

const setBearerToken = (token: string) => {
  reactLocalStorage.set("token", token);
};

const getHeaderConfig = () => {
  const AUTH_TOKEN =
    reactLocalStorage.get("token") !== undefined
      ? reactLocalStorage.get("token")
      : "";
  return {
    headers: {
      "Content-Type": "application/json",
      token: `Bearer ${AUTH_TOKEN}`,
    },
  };
};

const getBearerToken = () => {
  const AUTH_TOKEN = reactLocalStorage.get("token");
  return AUTH_TOKEN;
};



//  Add to cart
const setAddToCart = (cartList) => {
    reactLocalStorage.set("CartList", cartList);
};

const getAddToCart = ()=>{
  let getCart = reactLocalStorage.get("CartList");
  return getCart;
}

//  Branch ID

const setBranchId = (branchId) => {
  reactLocalStorage.set("branchId", branchId);
};

const getBranchId = () => {
  let branchId = reactLocalStorage.get("branchId");
  return branchId;
};

//  Customer ID

const removeCustomerId = () => {
  let remove = reactLocalStorage.remove("customerId");
  return remove;
};


const setCustomerId = (id: string) => {
  reactLocalStorage.set("customerId", id);
};

const getCustomerId = () => {
  let customer = reactLocalStorage.get("customerId");
  return customer;
};

const setCheckedbox = () =>{
  reactLocalStorage.set("isChecked", true);
}

const getCheckedbox = () =>{
  reactLocalStorage.get("isChecked");
}

const setCustomerNames = (name: string) => {
  reactLocalStorage.set("customerName", name);
};

const getCustomerName = () => {
  let customerName = reactLocalStorage.get("customerName");
  return customerName;
};

// CorporateID //

const setCorporateId = (id: string) => {
  reactLocalStorage.set("corporateId", id);
};

const getCorporateId = () => {
  let corporateId = reactLocalStorage.get("corporateId");
  return corporateId;
};

// Product status ID 

const setOrderStatus = (id) => {
  reactLocalStorage.set("statusId", id);
};
const getOrderStatus = () => {
 let statusId =  reactLocalStorage.get("statusId");
  return statusId;
};

// OrderKeyId
const setOrderKeyId = (status) => {
  reactLocalStorage.set("paymentStatus", status);
}

const getOrderKeyId = () => {
  let OrderKeyId =  reactLocalStorage.get("paymentStatus");
   return OrderKeyId;
 };

const setInvoiceId = (status) => {
  reactLocalStorage.set("invoiceid", status);
}

const getInvoiceId = () => {
  let invoiceKeyId =  reactLocalStorage.get("invoiceid");
   return invoiceKeyId;
 };

 const setInvId = (status) =>{
  reactLocalStorage.set("invId",status)
 }

 const getInvId = () => {
  let invKeyId =reactLocalStorage.get("invId");
  return invKeyId;
 }

 const setTwalletInvsId = (status) => {
  reactLocalStorage.set("twalletInvcId",status);
 }

 const getTwalletInvsId = () => {
  let twalletInvcKeyId = reactLocalStorage.get("twalletInvcId");
  return twalletInvcKeyId
 }

 const setPaymentUrl = (tinyUrl) => {
  let payUrl = reactLocalStorage.set("payId",tinyUrl);
  return payUrl;
 }

 const getPaymentUrl = () => {
  let paymentsUrl = reactLocalStorage.get("payId");
  return paymentsUrl;
 }

 const setEstimationId = (estiId) =>{
  let estimationId = reactLocalStorage.set("estimationId",estiId);
  return estimationId
 }

 const getEstimationId = () =>{
  let estimationId  =reactLocalStorage.get("estimationId");
  return estimationId;
  }

export {
  getInvId,
  setInvId,
  setAddToCart,
  setBearerToken,
  getBearerToken,
  getHeaderConfig,
  setCustomerId,
  getCustomerId,
  removeCustomerId,
  setBranchId,
  getBranchId,
  getAddToCart,
  getOrderStatus,
  setOrderStatus,
  setCustomerNames,
  getCustomerName,
  setCorporateId,
  getCorporateId,
  setOrderKeyId,
  getOrderKeyId,
  setInvoiceId,
  getInvoiceId,
  setPaymentUrl,
  getPaymentUrl,
  getCheckedbox,
  setCheckedbox,
  setEstimationId,
  getEstimationId,
  getTwalletInvsId,
  setTwalletInvsId
};
