import axios from "axios";
import { BASE_URL } from "../utility/UrlConstants";
import { getHeaderConfig } from "../utility/HeaderConfig";

const Offers = (filters: Object) => {
  return axios
    .get(
      `${BASE_URL}/admin/others/mobileoffer/get-page?page=0&size=500&mobileOfferFilter=${JSON.stringify(
        filters
      )}`,
      getHeaderConfig()
    )
    .then((response) => {
      const respData = {
        responseCode: response.status,
        response: response.data,
      };
      return respData;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

const getMyPurchaseData = (fromDate,toDate,customerId) => {
  return axios
  .get(
    `${BASE_URL}/salesestimation/sales/addsales/ecom-sales-items-list?fromDate=${fromDate}&toDate=${toDate}&customerId=${customerId}`,
    getHeaderConfig()
  )
  .then((response) => {
    const respData ={
      responseCode : response.status,
      response :response.data
    }
    return respData;
  })
  .catch((error) => {
    console.log(error)
    throw error;
  })
}

const getReferralPoints = (refId) => {
  return axios
  .get(
    `${BASE_URL}/customers/customerreferral/get-by-refid?refId=${refId}`,
    getHeaderConfig()
  )
  .then((response) => {
    const respData ={
      responseCode : response.status,
      response :response.data
    }
    return respData;
  })
  .catch((error) => {
    console.log(error)
    throw error;
  })
}

const ProductOrderData = (page, perPage, filterData) => {
  return axios
    .get(
      `${BASE_URL}/products/productorders/productadvanceitems/get-page?page=${page}&size=${perPage}&sort=createdAt,desc&prodAdvItemFilter=${JSON.stringify(filterData)}`,
      getHeaderConfig()
    )
    .then((response) => {
      const respData = {
        responseCode: response.status,
        response: response.data,
      };
      return respData;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

const getFilterData = () => {
  return axios
    .get(
      `${BASE_URL}/admin/others/orderstatus/get-all?filters={}`,
      getHeaderConfig()
    )
    .then((response) => {
      const respData = {
        responseCode: response.status,
        response: response.data,
      };
      return respData;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};


export {Offers,getMyPurchaseData,getReferralPoints,ProductOrderData,getFilterData}