import moment from "moment";
const DATE_WISE_FORMAT = "DD-MM-YYYY";
const YEAR_WISE_FORMAT = 'YYYY-MM-DD';
const DIAMOND_PRODUCT_TYPE = 'diamond';
const COIN_NAME = 'coin';
const COINS_NAME = 'coins';
const SALES_ACCOUNTS = 'sales accounts';
const MINIMUM_WET_STR = "0.000";
const weightDecimalPlaces = 3;
const HUNDRED = 100;
const FIVEHUNDRED = 500;
const THOUSAND = 1000;
const TW0THOUSAND = 2000;
const PLUS_SYMBOL = "+";
const ZERO_POINT_FIVE = 0.5;
const ONE_GRAM = 1;
const TWO_GRAM = 2;
const DIGIGOLD = 'digigold';
const DIGISILVER = 'digisilver';

export const PAYMENT_FIELDS = {
  currencyCode: 'INR',
  languageCode: 'EN'
};



//Date database to view Pacth(get) Date wise format
const dateMonthYearFormatUtcToLocal = (date: string) => {
  let localDate = "";
  if (date) {
    localDate = moment.utc(date).local().format(DATE_WISE_FORMAT);
  }
  return localDate;
};

// Date go to database (filter) check from date and to date Purity check it//
const currentDate = () => {
  let fromcurrentDate = `${moment().format("YYYY-MM-DD")} 00:00:00`;
  let tocurrentDate = `${moment().format("YYYY-MM-DD")} 23:59:59`;
  let date = {
    fromDate: moment(fromcurrentDate, `YYYY-MM-DD HH:mm:ss`).utc().format(),
    toDate: moment(tocurrentDate, `YYYY-MM-DD HH:mm:ss`).utc().format(),
  };
  return date;
};


const filterFormatFromDate = (date: string) => {
  let localDate = '';
  const dateAndTime = `${date} 00:00:00`;
  if (date) {
    localDate = moment(dateAndTime, 'YYYY-MM-DD HH:mm:ss')
      .utc()
      .format();
  }
  return localDate;
};

const filterFormatToDate = (date: string) => {
  let localDate = '';
  const dateAndTime = `${date} 23:59:59`;
  if (date) {
    localDate = moment(dateAndTime, 'YYYY-MM-DD HH:mm:ss')
      .utc()
      .format();
  }
  return localDate;
};

//// Current date and Time ///
const currentRegister = () => {
  const enrollmentDate = `${moment().format("YYYY-MM-DD")}`;
  const enrollmentTime = `${moment().format("HH:mm:ss")}`;
  const enrollmentDateWithTime = `${enrollmentDate}T${enrollmentTime}Z`;
  return enrollmentDateWithTime;
};

// single curentdate pass some moment formate date Book an appointment Page used //
const currentDateToUtcDate = (date: string) => {
  let utcDate = '';
  if (date) {
    utcDate = moment(date, `${YEAR_WISE_FORMAT} HH:mm:ss`)
      .utc()
      .format();
  }
  return utcDate;
};

const utcDateToCurrentDate = (date: string) => {
  let localDate = '';
  if (date) {
    localDate = moment(date, 'MMM Do YYYY, h:mm:ss a')
      .utc()
      .local()
      .format('YYYY-MM-DD');
  }
  return localDate;
};

const getUniqueID = (keyLength: number) => {
  let i; let key = ''; const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const charactersLength = characters.length;
  for (i = 0; i < keyLength; i++) {
    key += characters.substr(Math.floor((Math.random() * charactersLength) + 1), 1);
  }
  return key;
};

const numberCutOff = (num, fixed) => {
  // eslint-disable-next-line
  if (Number.isFinite(num) && !!num) {
    const toFixedReg = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
    return num.toString().match(toFixedReg)[0];
  } else {
    return 0;
  }
};

export {
  filterFormatFromDate,
   dateMonthYearFormatUtcToLocal,
   currentDate,
   currentRegister, 
   currentDateToUtcDate , 
   filterFormatToDate,
   numberCutOff,
   getUniqueID,
   utcDateToCurrentDate,
   DIAMOND_PRODUCT_TYPE,
   COINS_NAME, COIN_NAME, 
   SALES_ACCOUNTS,
   MINIMUM_WET_STR,
   weightDecimalPlaces,
   HUNDRED,
   FIVEHUNDRED,
   THOUSAND,
   TW0THOUSAND,
   PLUS_SYMBOL,
   ZERO_POINT_FIVE,
   ONE_GRAM,
   TWO_GRAM,
   DIGIGOLD,
   DIGISILVER,
   DATE_WISE_FORMAT,
   YEAR_WISE_FORMAT
  };
