import React, { memo, useEffect, useState } from "react";
import { login } from "../api/LoginApi.js";
import { CustomerAutoSearch, CustomergetAll } from "api/CustomerApi.js";
import tw from "twin.macro";
import {
  getBranchId,
  setBearerToken,
  setCustomerId,
  setCustomerNames,
} from "../utility/HeaderConfig.js";
import { motion } from "framer-motion";
import { currentRegister } from "../utility/Constant";
import toast from "react-hot-toast";
import { useCartContext } from "context/UseCartContext.js";
import loginBg from "images/login/loginBg.jpg";
import styled from "styled-components";
import Divider from "../images/MyScheme/divider.png";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg/svg-decorator-blob-7.svg";
import ForgetPassword from "./ForgetPassword.js";
import { reactLocalStorage } from "reactjs-localstorage";

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-1/2 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3   text-custom-100`}
`;

// eslint-disable-next-line
const TopbarContainer = tw.div`text-black bg-custom-200`;

function LoginModel(props) {
  const [mobileNo, setMobileNo] = useState("");
  const [password, setPassword] = useState("");
  const [MobileInvalid, setMobileInvalid] = useState(false);
  const [passwordInvalid, setPasswordInvalid] = useState("");
  const [emptyField, setEmptyField] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [notExit, setnotExit] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [CustomerName, setCustomerName] = useState([]);
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [forgetPwd, setForgetPwd] = useState(false);
  const [isCustomerAvalible , setIsCustomerAvalible] =useState([]);
  const { dispatch } = useCartContext();

  const submitForm = (e) => {
    e.preventDefault();
    postAjaxRequest();

    if (rememberMe) {
      reactLocalStorage.set(
        "rememberedUser",
        JSON.stringify({ mobileNo, password })
      );
    } else {
      reactLocalStorage.remove("rememberedUser");
    }
  };

  useEffect(() => {
    const rememberedUser = reactLocalStorage.get("rememberedUser");
    if (rememberedUser) {
      const { mobileNo, password } = JSON.parse(rememberedUser);
      setMobileNo(mobileNo);
      setPassword(password);
      setRememberMe(true);
    }
  }, []);

  const postAjaxRequest = () => {
    const mobileReg = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    // Invalid message validate //
    if (!mobileReg.test(mobileNo) && mobileNo !== "") {
      setMobileInvalid(true);
    } else {
      setMobileInvalid(false);
    }

    // Empty Validate Field //
    const validateField = () => {
      if (mobileNo && password && mobileReg.test(mobileNo)) {
        return true;
      } else {
        setEmptyField(true);
        return false;
      }
    };

    if (validateField()) {
      const loginDetails = {
        username: mobileNo,
        password: password,
      };
      const responseData = login(JSON.stringify(loginDetails));
      responseData.then(
        (data) => {
          if (
            data &&
            data.authStatus &&
            data.authStatus.toLowerCase() === "success"
          ) {
            const roleName = data.roles ? data.roles[0] : "";
            if (data.token && roleName !== "") {
              setLoading(true);
              setBearerToken(data.token);
              setPasswordInvalid("");
              getCustomer();
            }
          }
        },
        (err) => {
          if(err.response) {
            getAvailCustomer();
            const errorMsg = err.response.data.errorMsg.toLowerCase().includes("bad")  ? 
            "Bad Credentials" :"Customer not exist in This mobile number. Please register as new customer for this mobile number!"
            toast.error(errorMsg);
            setPasswordInvalid(errorMsg);
          }
        }
      );
    }
  };

  const close = () => {
    dispatch({ type: "MODAL", payload: false });
  };

  const getCustomer =async () => {

    const filter = {
      mobile: `${mobileNo}`,
      branchDetailsEntity: getBranchId(),
    };
    const respData =await CustomergetAll(filter);
    try {
      if(respData.response.length === 1){
        setCustomerId(respData.response[0].id);
        setCustomerNames(respData.response[0].name);
        toast.success("Logged in successfully...!", {
                  duration: 1000,
                  position: "top-right",
                });
        dispatch({ type: "LOGIN", payload: respData.response });   
        setTimeout(() => {
                  dispatch({ type: "MODAL", payload: false });
                }, 1000);
      }else if (respData.response.length > 1 && mobileNo.length > 9) {
              setShowModal(true);
              setCustomerName(respData.response);
      } else {
              setnotExit(true);
      }setLoading(false);
    } catch (error) {
      console.log(error);
      
    };
  };
  
  const getAvailCustomer = () => {
    const customerSearch = `mobile=${mobileNo}&beforeCreatedAt=${currentRegister()}`;
    const response = CustomerAutoSearch(customerSearch);
    response.then(
      (data) => {
        setIsCustomerAvalible(data.response);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const forget = () => {
    setForgetPwd(true);
  };

  const selectUser = (e) => {
    let customer = e.target.options[e.target.selectedIndex].text;
    setCustomerNames(customer);
    setCustomerId(e.target.value);
    toast.success("Logged in successfully...!", {
      duration: 1000,
      position: "top-right",
    });
    dispatch({ type: "LOGIN", payload: [e.target.values] });
    setTimeout(() => {
      dispatch({ type: "MODAL", payload: false });
    }, 1000);
   
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        tw="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
      >
        <div
          tw="relative fixed items-center flex bg-white outline-none focus:outline-none rounded-lg"
          className="col-11 col-sm-11 col-md-10 col-lg-8 col-xl-7  m-sm-0 "
          style={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <div className="d-none d-sm-block col-sm-5">
            <img src={loginBg} className="w-100 h-100" tw="rounded-l-lg" alt="login bg" />
          </div>
          {forgetPwd ? (
            <ForgetPassword />
          ) : (
            <div className="col-12 col-sm-7 position-relative  overflow-hidden">
              <DecoratorBlob1 />
              <DecoratorBlob2 />
              <div tw="p-2 py-5  sm:p-5 xl:p-16">
                <div tw="uppercase tracking-wide  text-xl sm:text-3xl text-black font-semibold text-center">
                  Login
                  <img
                    src={Divider}
                    alt="heading-divider"
                    tw="m-auto mt-2 w-1/4"
                  />
                </div>
                <form>
                  <div tw="my-6">
                    <div tw="relative">
                      <div tw="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          fill="currentColor"
                          className="bi bi-person"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                        </svg>
                      </div>
                      {(emptyField && mobileNo === "") ||
                      MobileInvalid ||
                      passwordInvalid ? (
                        <div tw="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#FF0000"
                            className="bi bi-exclamation-circle"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                          </svg>
                        </div>
                      ) : (
                        ""
                      )}
                      <input
                        type="tel"
                        pattern="[0-9]{10}"
                        autocomplete="off"
                        id="email-address-icon"
                        value={mobileNo}
                        onChange={(e) => {
                          setMobileNo(e.target.value.trim());
                        }}
                        tw="focus:outline-none focus:border-custom-100
                              focus:ring-1   bg-gray-200 border border-gray-300 
                              text-gray-900 text-sm rounded-lg  block w-full px-10 py-4 tracking-wide"
                        placeholder="Enter registered Mobile No"
                        className={`${
                          (emptyField && mobileNo === "") ||
                          MobileInvalid ||
                          passwordInvalid
                            ? "border border-danger"
                            : ""
                        }`}
                      />
                    </div>
                    {emptyField && mobileNo === "" ? (
                      <p tw="text-red-500 mt-1 mx-5 text-sm">
                        Enter Your Mobile Number
                      </p>
                    ) : (
                      " "
                    )}

                    {MobileInvalid && mobileNo !== "" ? (
                      <p tw="text-red-500 mt-1 mb-0 mx-5 text-sm">
                        Mobile Number Field is Invalid
                      </p>
                    ) : (
                      ""
                    )}

                    {notExit && (
                      <>
                        <p tw="text-red-500 mt-1 mx-5 text-xs my-0 text-sm">
                          Customer not exist in this mobile number.
                        </p>
                        <p tw="text-red-500 mt-1 mx-5 text-xs my-0 text-sm">
                          Please register as new customer for this mobile
                          number!
                        </p>
                      </>
                    )}
                  </div>
                  <div tw="mt-6">
                    <div tw="relative">
                      <div tw="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-shield-lock"
                          viewBox="0 0 16 16"
                        >
                          <path d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z" />
                          <path d="M9.5 6.5a1.5 1.5 0 0 1-1 1.415l.385 1.99a.5.5 0 0 1-.491.595h-.788a.5.5 0 0 1-.49-.595l.384-1.99a1.5 1.5 0 1 1 2-1.415z" />
                        </svg>
                      </div>
                      {(emptyField && password === "") || passwordInvalid ? (
                        <div tw="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#FF0000"
                            className="bi bi-exclamation-circle"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                          </svg>
                        </div>
                      ) : (
                        ""
                      )}
                      <input
                        type={`${showPassword ? "text" : "password"}`}
                        id="email-address-icon"
                        tw="focus:outline-none focus:border-custom-100
                      focus:ring-1   bg-gray-200 border border-gray-300 
                      text-gray-900 text-sm rounded-lg  block w-full px-10 py-4 tracking-wide"
                        placeholder="Enter Your Password"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                        className={`${
                          (emptyField && password === "") || passwordInvalid
                            ? "border border-danger"
                            : ""
                        }`}
                      />
                      <div
                        onClick={() => setShowPassword(!showPassword)}
                        tw="absolute inset-y-0 right-0 flex items-center pr-3"
                      >
                     {showPassword ? (<i class="bi bi-eye mx-4"></i>) : (<i class="bi bi-eye-slash mx-4"></i>)}
                      </div>
                    </div>
                    {emptyField && password === "" ? (
                      <p tw="text-red-500 mt-1 mx-5 text-sm">
                        Enter Your Password
                      </p>
                    ) : (
                      " "
                    )}
                  </div>

                  <div className="d-flex my-3 justify-content-between">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={rememberMe}
                        onChange={(e) => setRememberMe(e.target.checked)}
                        value=""
                        id="flexCheckDefault"
                      />
                      <label
                        className="form-check-label"
                        for="flexCheckDefault"
                        tw="text-sm "
                      >
                        Remember me
                      </label>
                    </div>
                    <span
                      tw="text-sm hover:text-custom-100 cursor-pointer"
                      onClick={forget}
                    >
                      Forgot Password ?
                    </span>
                  </div>

                  <button
                    type="submit"
                    tw="mt-1 transition w-full text-white  bg-custom-100 active:bg-custom-100 tracking-wider font-bold focus:outline-none   rounded-lg text-base py-3 text-center uppercase"
                    onClick={submitForm}
                    disabled={Loading ? true : ""}
                    className={`${Loading ? "bg-secondary" : ""} btn2 `}
                  >
                    {Loading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm text-white"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="mx-2 text-white">Loading . . .</span>
                      </>
                    ) : (
                      <>Sign In</>
                    )}
                  </button>
                  <div tw="pt-6  text-center">
                    New User ?
                    <span tw="no-underline">
                      <span
                        tw="text-blue-800 hover:text-custom-100 mx-2 text-sm tracking-wider font-bold cursor-pointer"
                        onClick={() =>
                          dispatch({ type: "MODAL", payload: "register" })
                        }
                      >
                        Sign up
                      </span>
                    </span>
                  </div>
                </form>
                <div tw="pt-6 text-center">
                  New user account creation
                  <span
                    tw="no-underline"
                    onClick={() =>
                      dispatch({ type: "MODAL", payload: "user" })
                    }
                  >
                    <span tw="text-blue-800 hover:text-custom-100 mx-2 text-sm tracking-wider font-bold cursor-pointer">
                      (Existing Customer)
                    </span>
                  </span>
                </div>
              </div>
            </div>
          )}
          <div className="position-absolute top-0 end-0" onClick={close}>
            <i
              className="bi bi-x m-2"
              tw="text-3xl text-gray-600 cursor-pointer hover:text-custom-100"
            ></i>
          </div>
        </div>
      </motion.div>
      {showModal ? (
        <>
          <motion.div
            initial={{ x: "700px", opacity: 0, scale: 0.5 }}
            animate={{ x: 0, opacity: 1, scale: 1 }}
            tw="justify-center  flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div tw="relative w-auto my-20 mx-auto w-1/4">
              {/*content*/}
              <div tw="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div tw="flex items-start justify-between items-center p-2 border-b border-solid border-custom-100 rounded-t">
                  <h6 tw="text-sm m-0 uppercase">Select User</h6>
                  <button
                    tw="p-1 ml-auto  border-0 text-gray-400 float-right text-xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <i className="bi bi-x-circle"></i>
                  </button>
                </div>
                {/*body*/}

                <div tw="relative p-6 flex-auto">
                  <select
                    value={CustomerName}
                    onChange={(e) => selectUser(e)}
                    tw="focus:outline-none focus:border-custom-100
                  focus:ring-1 "
                    className="form-select leaEkY"
                  >
                    <option value="select user">Select User</option>
                    {CustomerName.map((option, index) => (
                      <>
                        <option key={index} value={option.id}>
                          {option.name}
                        </option>
                      </>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </motion.div>
          <div tw="fixed inset-0 z-40" className="bg-dark bg-opacity-50"></div>
        </>
      ) : null}
    </>
  );
}

export default memo(LoginModel);
