import React, { useEffect, useState } from 'react'
import tw from "twin.macro";
import moment from 'moment';
import Skeleton from "react-loading-skeleton";
import no_data from "images/no_data.png";
import { getFilterData, ProductOrderData } from 'api/OffersApi';
import {Link}from "react-router-dom";
import { getBranchId, getCustomerId} from 'utility/HeaderConfig';
import { DATE_WISE_FORMAT, filterFormatFromDate, filterFormatToDate, YEAR_WISE_FORMAT } from 'utility/Constant';

const Container = tw.div`relative font-display overflow-hidden`;

function ProductOrderDetails({configImages}) {

  const [banner, setBanner] = useState([]);
  const [productOrder, setProductOrder] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loader, setLoader] = useState(true);
  const [expectedFromDate, setExpectedFromDate] = useState('');
  const [expectedToDate, setExpectedToDate] = useState('');
  const [filterOrderId, setFilterOrderId] = useState('');
  const [getParticular, setGetParticular] = useState([]);
  const [selectOrderStatus, setSelectOrderStatus] = useState({ title: "", value: "" });
  const [isInitialState, setIsInitialState] = useState(false);

  useEffect(()=>{
    getBanner();
    getProductOrderData();
    // eslint-disable-next-line
  },[]);

  useEffect(()=>{
    getProductOrderData();
//eslint-disable-next-line
  },[isInitialState])

  const getBanner = () => {
    configImages &&
      configImages.E_COMMERCE_BANNERS.forEach((data) => {
        if (data.key === "referral") {
          setBanner(data.value);
        }
      });
  };

  const orderFilterData = async () => {
    const filterGetData = await getFilterData();
    let getParticular = [];
    if (filterGetData.errorCode === undefined && filterGetData.response.length > 0) {
      getParticular = filterGetData.response.filter((item) =>
        item.orderStatusName.toUpperCase() === 'PENDING' ||
        item.orderStatusName.toUpperCase() === 'CANCEL' ||
        item.orderStatusName.toUpperCase() === 'DEALER_ISSUE' ||
        item.orderStatusName.toUpperCase() === 'SOLD' ||
        item.orderStatusName.toUpperCase() === 'RECEIVED'
      );
      getParticular = getParticular.map((item) => ({ title: item.orderStatusName, value: item.id }));
      getParticular.forEach((item) => {
        switch (item.title.toUpperCase()) {
          case 'PENDING':
            item.title = 'ISSUE PENDING';
            break;
          case 'CANCEL':
            break;
          case 'SOLD':
            item.title = 'SOLD';
            break;
          case 'RECEIVED':
            item.title = 'READY TO DELIVERY';
            break;
          case 'DEALER_ISSUE':
            item.title = 'DEALER PENDING';
            break;
          default:
            break;
        }
      });
    }
    setGetParticular(getParticular);
  };

  const getProductOrderData = async () => {
    const branchId = getBranchId();
    const customerId = getCustomerId();
    let currentDate = moment().utc().set({ hour: 18, minute: 29, second: 59 }).format('YYYY-MM-DDTHH:mm:ss[Z]');
    let filterData = {
      createdAtFrom: filterFormatFromDate(moment(expectedFromDate, DATE_WISE_FORMAT).format(YEAR_WISE_FORMAT)),
      createdAtTo: filterFormatToDate(moment(expectedToDate, DATE_WISE_FORMAT).format(YEAR_WISE_FORMAT)),
      branchDetailsEntity: branchId,
      beforeCreatedAt: currentDate,
      productAdvanceSeqNo: filterOrderId,
      orderItemStatusId: selectOrderStatus.value,
      customerId: customerId
    };
    if (expectedFromDate === '') {
      delete filterData.createdAtFrom;
    }
    if (expectedToDate === '') {
      delete filterData.createdAtTo;
    }
    if (filterOrderId === '') {
      delete filterData.productAdvanceSeqNo;
    }
    if (selectOrderStatus.value === '') {
      delete filterData.orderItemStatusId;
    }
    let productOrder = [];
    let totalPages = 0;
    let totalCount = 0;
    const responseData = await ProductOrderData(0, 20, filterData);
    if (responseData.responseCode === 200) {
      totalPages = responseData.response.totalPages;
      totalCount = responseData.response.totalCount;
      productOrder = responseData.response.content;
    }
    setProductOrder(productOrder);
    setTotalCount(totalCount);
    setTotalPages(totalPages);
    setLoader(false);
  };
  
  const updateFieldState = (fieldName,fieldValue) =>{
    if(fieldName === "createdAtFrom"){
      setExpectedFromDate(fieldValue)
      return
    }
    if(fieldName === "createdAtTo"){
      setExpectedToDate(fieldValue)
      return
    }
    if(fieldName === "orderNumber"){
      setFilterOrderId(fieldValue)
      return
    }
  };

  const handleChange = (value) => {
    const selectedOption = getParticular.find(option => option.value === value);
    if (selectedOption) {
      setSelectOrderStatus({
        title: selectedOption.title,
        value: selectedOption.value,
      });
    }
  };

  const search =() => {
    getProductOrderData();
  }; 

  const reset =() => {
    setExpectedFromDate("");
    setExpectedToDate("");
    setFilterOrderId("");
    setSelectOrderStatus({ title: "", value: "" });
    setIsInitialState(!isInitialState);
  };

  
  return (
    <Container>
       {banner && banner.length > 0 ? (
        <img src={banner} alt="New Arrivals Products" className="w-100" />
      ) : (
        <Skeleton tw="w-full" style={{ height: "200px" }} />
      )}
      <div className="ptSans px-sm-5 px-0" tw="text-sm pt-3">
        <Link to="/" tw="mx-3 no-underline text-black">
          Home
        </Link>
        /
        <span tw="ml-3 text-custom-100 capitalize tracking-wide ">
        product Order Details        
        </span>
      </div>
      <div className="py-sm-5  pb-2 mb-5 position-relative">
       <div className="container container-influid-md">
       <div className="container pt-3 pt-sm-5 ptSans">
        <div className="row flex-wrap align-items-center">
          <div className="col-md-1 d-md-none  d-lg-none d-xl-block">
            <span>Filter By :</span>
          </div>
          <div className=" col-md-6 col-lg-3 col-xl-2  mb-2 py-sm-0 py-2">
            <label>From Date :</label>
          <input
                type="date"
                className="form-control w-100 py-2" 
                id="createdAtFrom"
                value={expectedFromDate}
                onChange={e =>
                  updateFieldState("createdAtFrom", e.target.value)
                }
              />
          </div>
          <div className=" col-md-6 col-lg-3 col-xl-2  mb-2 py-sm-0 py-2">
            <label>To Date : </label>
          <input
                type="date"
                className="form-control w-100 py-2 " 
                id="createdAtTo"
                value={expectedToDate}
                onChange={ e =>
                  updateFieldState("createdAtTo",e.target.value)
                }
              />
          </div>
          <div className="mb-2 col-md-6 col-lg-3 py-sm-0 py-2">
          <input
                type="text"
                className="form-control w-100 py-2 mt-4" 
                id="orderNumber"
                value={filterOrderId}
                placeholder="Enter Order Number"
                onChange={e =>
                  updateFieldState("orderNumber",e.target.value)
                }
              />
          </div>
          <div className=" col-md-6 col-lg-3 col-xl-2  mb-2 py-sm-0 py-2">
          <select
                type="text"
                className="selectpicker form-select py-2 mt-4" 
                id="selectedOrderStatus"
                name="OrderStatus"
                value={selectOrderStatus.value}
                onFocus={orderFilterData}
                onChange={e => handleChange(e.target.value)}
              >
                <option hidden className="text-uppercase">
                Pending Status
              </option>
              {getParticular.length === 0  ? (
                <option className="text-lowercase" tw="text-lg">
                  No Data
                </option>
              ) : (
                getParticular.map((data,index) => (
                  <option key={index} value={data.value} tw="uppercase">
                    {data.title}
                  </option>
                ))
              )}
                </select>
          </div>
          <div className=" col-md-6 col-lg-3 col-xl-2  mb-2 py-sm-0 py-2">
              <button 
              type="submit"
              className="btn btn-primary mt-4" 
              onClick={search}
              >
              Apply
              </button>
              <button 
              type="submit"
              className="btn btn-primary mt-4 mx-2" 
              onClick={reset}
              >
              Reset
              </button>
          </div>
        </div>
      </div>
      {productOrder &&productOrder.length === 0 ?
    <img src={no_data} alt="no-data" className="col-md-5 m-auto" />  
          :
          <div className='row'>
        {productOrder.length > 0 && productOrder.map((item ,index) =>(
         <div className='col col-sm-6 col-md-6 col-lg-4 py-3' key={index}>
         <div className="shadow p-3 mb-5 bg-white rounded">
         {/* <div className='row'>
          <div className='col'>
             <b>Product Status</b>
          </div>
          <div className='col'>
          <b>Product Name</b>
          </div>
          <div className='col'>
          <b>Order No</b>
          </div>
          <div className='col'>
          <b>Order Date</b>
          </div>
         </div> */}
         {/* <div className='row'>
          <div className='col'>
             <b>Net Weight</b>
          </div>
          <div className='col'>
          <b>Less Weight</b>
          </div>
          <div className='col'>
          <b>Gross Weight</b>
          </div>
          <div className='col'>
             <b>Remarks</b>
          </div>
          <div className='col'>
          <b>Expected Date</b>
          </div>
         </div> */}
         <div className='row'>
         <div className='col'>
          <p tw='font-semibold'>Product Status</p>
          <p tw='font-semibold'>Product Name</p>
          <p tw='font-semibold'>Order No</p>
          {/* <p tw='font-semibold'>Order Date</p> */}
          {/* <p tw='font-semibold'>Remarks</p> */}
          <p tw='font-semibold'>Net Weight</p>
          <p tw='font-semibold'>Less Weight</p>
          <p tw='font-semibold'>Gross Weight</p>
          {/* <p tw='font-semibold'>Expected Date</p> */}
         </div>
         <div className='col'>
         <p> 
          {item.orderStatusName === 'Sold'?"SOLD": 
           item.orderStatusName === 'RECEIVED'? "READY TO DELIVERY":
           item.orderStatusName === 'Dealer_Issue'?"DEALER PENDING":
           item.orderStatusName === 'CANCEL'?"CANCEL":
           item.orderStatusName === 'PENDING'?"ISSUE PENDING":""
          }
         </p>
         <p>{item.productName}</p>
         <p>{item.productAdvSeqNum}</p>
         {/* <p>{item.productName}</p> */}
         {/* <p>{item.remark}</p> */}
         <p>{item.orderNetWeight}</p>
         <p>{item.orderLessWeight}</p>
         <p>{item.orderGrossWeight}</p>
         {/* <p>{item.productName}</p> */}
         </div>
         </div>
        </div>
         </div>
        ))}
        </div>
         }
       </div>
      </div>
    </Container>
  )
}

export default ProductOrderDetails