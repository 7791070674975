import axios from "axios";
import { BASE_URL } from "../utility/UrlConstants";
import { getBearerToken, getHeaderConfig } from "../utility/HeaderConfig";

const ONLINE_TRANSACTION_API_URL = `${BASE_URL}/accounts/onlinePayment`;


const schemeStatus = (filters: Object) => {
  return axios
    .get(
      `${BASE_URL}/savingsscheme/customersavingdetails/get-page?page=0&size=50&sort=createdAt,desc&chitEnrolDtlsFilter=${JSON.stringify(
        filters
      )}`,
      getHeaderConfig()
    )
    .then((response) => {
      const respData = {
        responseCode: response.status,
        response: response.data,
      };
      return respData;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

const getAllPaymentModeList = () => {
  return axios
    .get(
      `${BASE_URL}/admin/accounts/paymentmode/metadata/get-all`,
      getHeaderConfig()
    )
    .then((response) => {
      const respData = {
        responseCode: response.status,
        response: response.data,
      };
      return respData;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

const paymentHitory = (filters: Object) => {
  return axios
    .get(
      `${BASE_URL}/savingsscheme/chitcustomerpayment/get-page?page=0&size=50&sort=createdAt,desc&chitCustPaymntDtlsFilter=${JSON.stringify(
        filters
      )}`,
      getHeaderConfig()
    )
    .then((response) => {
      const respData = {
        responseCode: response.status,
        response: response.data,
      };
      return respData;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

const onlinePayment = (filters: Object) => {
  return axios
    .get(
      `${BASE_URL}/accounts/onlinePayment/get-page?page=0&size=50&sort=createdAt,desc&onlinePaymentFilter=${JSON.stringify(
        filters
      )}`,
      getHeaderConfig()
    )
    .then((response) => {
      const respData = {
        responseCode: response.status,
        response: response.data,
      };
      return respData;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

const getPgToken = (params) => {
  axios.defaults.headers.common.token = `Bearer ${getBearerToken()}`;
  return axios
    .get(`${ONLINE_TRANSACTION_API_URL}/get-pg-token`, { params }, getHeaderConfig())
    .then(response => {
      const respData = {
        responseCode: response.status,
        response: response.data
      };
      return respData;
    })
    .catch(error => {
      console.log(error);
      throw error;
    });
};

const createOnlinePayment = (requestBody: Object) => {
  return axios
    .post(
      `${ONLINE_TRANSACTION_API_URL}/create`,
      requestBody,
      getHeaderConfig()
    )
    .then(response => {
      const respData = {
        responseCode: response.status,
        response: response.data
      };
      return respData;
    })
    .catch(error => {
      console.log(error);
      throw error;
    });
};

const createOnlinePaymentPayg = (requestBody: Object, TOKEN: string) => {
  return axios
    .post('https://paygapi.payg.in/payment/api/order/create', requestBody, {
      headers: {
        Authorization: `${TOKEN}`
      }
    })
    .then(response => {
      const respData = {
        responseCode: response.status,
        response: response.data
      };
      return respData;
    })
    .catch(error => {
      console.log(error);
      throw error;
    });
};

const updateOnlinePayment = (requestBody: Object) => {
  return axios
    .put(`${ONLINE_TRANSACTION_API_URL}/update`, requestBody, getHeaderConfig())
    .then(response => {
      const respData = {
        responseCode: response.status,
        response: response.data
      };
      return respData;
    })
    .catch(error => {
      console.log(error);
      throw error;
    });
};

export { schemeStatus , paymentHitory, onlinePayment,getAllPaymentModeList,getPgToken,createOnlinePayment,createOnlinePaymentPayg,updateOnlinePayment };
