import React, { useEffect, useState } from 'react';
import { Heading } from 'pages/BookAnAppointment';
import { motion } from "framer-motion";
import styled from "styled-components";
import Carousel from "react-elastic-carousel";
import tw from "twin.macro";
import { NavLink } from './ProductCategory';
import { css } from "styled-components/macro"; //eslint-disable-line
import no_data from "images/no_data.png";
import Noimg from "../images/no_img.jpg";
import { getProduct, orderStatus } from 'api/ProductApi';
import { currentRegister } from 'utility/Constant';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getBranchId } from 'utility/HeaderConfig';


const CardContainer = tw.div`mt-4 w-full`;
const Card = tw(
  motion.a
)`rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0 no-underline`;
const CardImageContainer = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`bg-center bg-cover relative rounded `}
`;

function BestSelling() {


  const history = useHistory();

  const [bestSellerProduct, setBestSellerProduct] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Promise.all([
      getOrderStatusSale(),
      getOrderStatusTransfer(),
      getOrderStatusSold()
    ])
      .then((result) => {
        getBestsellerData(result[0], result[1], result[2]);
      })
      .catch((err) => console.error(err));
    //eslint-disable-next-line
  }, []);

  const getOrderStatusSale = () => {
    return orderStatus({ orderStatusName: "sale" })
      .then((data) => {
        return data.response[0].id;
      })
      .catch((err) => console.error(err));
  }

  const getOrderStatusTransfer = () => {
    return orderStatus({ orderStatusName: "Transferred" })
      .then((data) => {
        return data.response[0].id;
      })
      .catch((err) => console.error(err));
  };

  const getOrderStatusSold = () => {
    return orderStatus({ orderStatusName: "Sold" })
      .then((data) => {
        return data.response[0].id;
      })
      .catch((err) => console.error(err));
  };

  const getBestsellerData = (
    orderSaleStatus,
    orderTransferStatus,
    orderSoldStatus
  ) => {
    setLoading(true);
    getProduct(10, {
      branch: getBranchId(),
      productLabel: 'BestSellers',
      removeNTP: 'NTP-',
      removeHP: 'HP-',
      beforeCreatedAt: currentRegister(),
      sortType: true,
      statusId: `${orderSaleStatus},${orderTransferStatus},${orderSoldStatus}`,
      demoStatus: "Demo_Completed,Demo_Cancelled,Available_For_Demo",
    })
      .then((data) => {
        setBestSellerProduct(data.response.content);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      })
  }

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 900, itemsToShow: 4 },
  ];

  return (
    <>
      {bestSellerProduct.length > 0 &&
        <>
          <div className="container py-2 text-center ">
            <div tw="flex justify-center">
              <Heading>Best Selling</Heading>
            </div>
          </div>
          <div className="container">
            {bestSellerProduct.length === 0 ? (
              <img src={no_data} alt="no-data" className="col-md-4 m-auto" />
            )
              : (
                <Carousel
                  itemPadding={[0, 10]}
                  itemsToShow={5}
                  outerSpacing={0}
                  showEmptySlots
                  breakPoints={breakPoints}
                  className="newArrivalsComponent"
                >
                  {bestSellerProduct.map((categoryItem, index) => (
                    <div className="w-100" key={index}>
                      <CardContainer
                        onClick={() =>
                          history.push("BestSellingComponent", {
                            category: categoryItem,
                          })
                        }
                        tw="cursor-pointer relative"
                      >
                        <Card className="group position-relative ">
                          <div className="overflow-hidden">
                            <CardImageContainer
                              className="group"
                              tw="h-64 border border-gray-300 rounded-t-lg flex justify-center w-full  transform transition duration-500  group-hover:(scale-125 rotate-12)"
                              imageSrc={
                                categoryItem.images === "Image_Not_Available" || categoryItem.images === "-" || categoryItem.images === null || categoryItem.images === "@#@"
                                  ? Noimg
                                  : categoryItem.images.split("@#@")[0]
                              }
                            ></CardImageContainer>
                          </div>
                          <div tw="text-center  border border-gray-300  font-semibold bg-white px-5 py-3 text-black text-xs uppercase">
                            <NavLink className="mb-2">
                              {categoryItem.category}
                            </NavLink>
                            <span tw="text-custom-100">
                              Explore{" "}
                              <i className="mx-1 bi bi-arrow-up-right-circle-fill"></i>
                            </span>
                          </div>
                        </Card>
                      </CardContainer>
                    </div>
                  ))}
                </Carousel>
              )}
            <div className="d-flex justify-content-center py-0 sm:py-3">
              <div
                type="button"
                tw="transition-all duration-700 mt-4 border border-custom-100 hover:text-white hover:bg-custom-100 px-20 py-2 text-sm mb-4"
                onClick={() => history.push("BestSellingComponent")}
              >
                {loading ? "Loading ..." : "View More"}
              </div>
            </div>
          </div>
        </>
      }
    </>
  )
}

export default BestSelling