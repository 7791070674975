import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import tw from "twin.macro";
import {
  Language,
  EnquierySource,
  EnquieryStatus,
  EmplyeeId,
  EnquieryTypeId,
  createEnquiry,
  getEnquiryByPage,
} from "../api/BookAnAppointmentApi.js";
import { getBranchId, getCustomerId } from "../utility/HeaderConfig.js";
import { currentDateToUtcDate, currentRegister } from "../utility/Constant";
import moment from "moment";
import { CustomerAutoSearch } from "../api/CustomerApi";
import toast, { Toaster } from "react-hot-toast";
import Noimg from "../images/no_img.jpg";
import Skeleton from "react-loading-skeleton";
import form from "images/enquiry/form.png";
import custCare from "images/enquiry/custCare.png";
import jewel from "images/enquiry/jewel.png";
import { getAllProductsType } from "../api/ProductTypeApi.js";

const Container = tw.div`relative`;
export const Heading = tw.h1`text-center  block text-black font-bold text-3xl m-0 py-2 uppercase border-b-2 border-custom-100`;

export default ({ configImages }) => {
  const [name, setName] = useState("");
  const [mobileno, setmobileno] = useState("");
  const [email, setemail] = useState("");
  const [enquiry, setEnquiry] = useState("");
  const [language, setLanguage] = useState([]);
  const [languageid, setLanguageid] = useState("");
  const [scheduleDate, setScheduleDate] = useState(
    moment().add(1, "hours").format("YYYY-MM-DDTHH:mm")
  );
  const [duration, setDuration] = useState(30);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [customerId, setCustomerId] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState("");
  const [invalidMobile, setinvalidMobile] = useState("");
  const [availableMobile, setAvailableMobile] = useState("");
  const [validInput, setValidInput] = useState("");
  const [validTime, setValidTime] = useState(true);
  const [Loading, setLoading] = useState(false);
  const [Latitude, setLatitude] = useState("");
  const [Longitude, setLongitude] = useState("");
  const [EnquieryId, setEnquieryId] = useState("");
  const [EnquieryStatusId, setEnquieryStatusId] = useState("");
  const [EmployeeId, setEmployeeId] = useState("");
  const [EnquieryTypeid, setEnquieryTypeid] = useState("");
  const [banner, setBanner] = useState([]);
  const [metalTypeList, setMetalTypeList] = useState([]);
  const [metalTypeid, setMetalTypeid] =useState("");
  const [currentlang, setCurrentlang] = useState([]);
  // const [ SampleReqData , setsampleReqDate ] = useState(`Schedule a Video Demo for me with Video Live at ${moment().add(1, 'hours').format('DD-MM-YYYY hh:mm A')}`)

  useEffect(() => {
    Selectlanguage();
    EnquierySourceId();
    EnquierySourceStatus();
    EnquieryEmployeeId();
    EnquieryType();
    GeoLocation();
    getBanner();
    selectMetalType();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    let initialDate = moment().add(1, "hours").format("YYYY-MM-DDTHH:mm");
    if (scheduleDate < initialDate) {
      setValidTime(false);
    } else {
      setValidTime(true);
    }
  }, [scheduleDate]);

  const getBanner = () => {
    configImages &&
      configImages.E_COMMERCE_BANNERS.forEach((data) => {
        if (data.key === "enquiry") {
          setBanner(data.value);
        }
      });
  };

  const location = useLocation();
  const myparam = location.state;

  const Selectlanguage = async() => {
    let languageList = [];
    const languageResData = await Language();
    if(languageResData.response && languageResData.response.length > 0){
      languageResData.response.forEach((item) => {
        const data = {
          value: item.id,
          title: item.languageName,
          selected: false,
        };
        languageList.push(data);
      });
    };
    setLanguage(languageList);
    let defValue = languageList.filter(language => language.title.toLowerCase() === "tamil");
    setCurrentlang(defValue);
    setSelectedProduct(myparam ? myparam : null);
    if (getCustomerId()) {
      setCustomerId(true);
    }
  };
  
  const selectMetalType = async () => {
    let filter = {};
    let enquiryProductTypeList = [];
    const respDatas = await getAllProductsType(filter);
    respDatas.response.forEach((item) =>{
      if(
        item.name.toLowerCase() === "gold" ||
        item.name.toLowerCase() === "silver" ||
        item.name.toLowerCase() === 'diamond'
      ){
        const response ={
          value: item.id,
          title: item.name,
          selected: false,
        };
        enquiryProductTypeList.push(response)
      }
    })
    setMetalTypeList(enquiryProductTypeList);
    setSelectedProduct(myparam ? myparam : null);
    if(getCustomerId()){
      setCustomerId(true);
    }
  };

  const EnquierySourceId = () => {
    EnquierySource().then((data) => {
      if (data.response.length > 0 && data) {
        let enquiryName = data.response;
        // filter(
        //   (item) => item.name && item.name.toLowerCase() === "web"
        // );
        let selectedEnquirySource = enquiryName.filter(item => item.name === 'ECOMM_WEB')
          // enquiryName.length > 0 ? enquiryName[9].id : "";
        setEnquieryId(selectedEnquirySource);
      }
    });
  };
  const EnquierySourceStatus = () => {
    EnquieryStatus().then((data) => {
      if (data.response.length > 0 && data) {
        let enquiryId = data.response.filter(
          (item) => item.name && item.name.toLowerCase() === "hot"
        );
        let selectedEnquiryId = enquiryId.length > 0 ? enquiryId[0].id : "";
        setEnquieryStatusId(selectedEnquiryId);
      }
    });
  };

  const EnquieryEmployeeId = () => {
    const employee = {
      name: "ONLINEPAYMENT",
    };
    EmplyeeId(JSON.stringify(employee)).then((data) => {
      if (data.response.length > 0 && data) {
        let enquiryName = data.response;
        let selectedEnquirySource =
          enquiryName.length > 0 ? enquiryName[0].id : "";
        setEmployeeId(selectedEnquirySource);
      }
    });
  };

  const EnquieryType = () => {
    EnquieryTypeId().then((data) => {
      if (data.response.length > 0 && data) {
        let enquiryTypeId = data.response.filter(
          (item) => item.name && item.name.toLowerCase() === "sales"
        );
        let selectedEnquiryTypeId =
          enquiryTypeId.length > 0 ? enquiryTypeId[0].id : "";
        setEnquieryTypeid(selectedEnquiryTypeId);
      }
    });
  };

  const GeoLocation = () => {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      },
      function (error) {
        console.error("Error Code = " + error.code + " - " + error.message);
      }
    );
  };

  let textarea = `Schedule a Video Demo for me with Video Live at ${moment(
    scheduleDate
  ).format("DD-MM-YYYY hh:mm A")}`;

  const updateEnquiryDetailState = (text) => {
    if (text !== "") {
      let data = "";
      if (text.length === 1) data = `${textarea} Additional Details: ${text}`;
      else {
        data = text;
      }
      setEnquiry(data);
    }
  };

  const submitForm = () => {
    if(getCustomerId() === undefined){
      toast.error("Kindly login / Resigter")
    }
    else if(validateField()) {
      customerAutoSearch();
    }
  };

  // date picker validation

  const validateField = () => {
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; //eslint-disable-line
    const mobileReg = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (!reg.test(email) && email !== "") {
      setInvalidEmail("Email Field is Invalid");
    } else {
      setInvalidEmail("");
    }
    if (!mobileReg.test(mobileno) && mobileno !== "") {
      setinvalidMobile("Mobile Field is Invalid");
    } else {
      setinvalidMobile("");
    }

    if (
      reg.test(email) &&
      mobileReg.test(mobileno) &&
      name !== "" &&
      languageid !== "" &&
      scheduleDate !== "" &&
      metalTypeid !== "" &&
      validTime
    ) {
      return true;
    } else {
      setValidInput(true);
    }

    if (validTime && customerId && languageid !== "" && metalTypeid !=="") {
      return true;
    } else {
      setValidInput(true);
    }
  };

  const customerAutoSearch = () => {
    setLoading(true);
    // let rememberedUser = localStorage.getItem('rememberedUser');
    // let user = JSON.parse(rememberedUser) ?? '';     
    // let mobileno = user.mobileNo ?? '';
    if(mobileno.trim().length > 0){
    const customerSearch = `mobile=${mobileno}&branchId=${getBranchId()}&beforeCreatedAt=${currentRegister()}`;
    CustomerAutoSearch(customerSearch).then((data) => {
      if (
        data.response.length > 0 &&
        data.response &&
        mobileno.trim().length > 0
      ) {
        setAvailableMobile("Customer already available in this mobile number");
      } else 
      if (selectedProduct && customerId) {
        const enquiryFilterData = {
          customerEntity: getCustomerId(),
          branchDetailsEntity: getBranchId(),
          productEntity: selectedProduct.id,
        };
        getEnquiryByPage(JSON.stringify(enquiryFilterData)).then((data) => {
          if (data.response.content.length > 0) {
            toast.error(
              "You have already booked an appointment for this product...!",
              {
                duration: 2000,
                position: "top-right",
              }
            );
            setLoading(false);
          } else {
            setAvailableMobile("");
            postAjaxRequest();
          }
        });
      } else {
        setAvailableMobile("");
        postAjaxRequest();
      }
    });
  } else {
    postAjaxRequest();
  }
  };

  const postAjaxRequest = () => {
    createCustomerEnquiry();
  };

  const createCustomerEnquiry = () => {
    setLoading(true);
    const demoEndDate = moment(scheduleDate, "YYYY-MM-DDThh:mm")
      .add(duration, "minutes")
      .format("DD-MM-YYYY hh:mm A");
    const newDemoStartDate = moment(scheduleDate, "YYYY-MM-DDThh:mm").format(
      "YYYY-MM-DD HH:mm:ss"
    );
    const newDemoEndDate = moment(demoEndDate, "DD-MM-YYYY hh:mm A").format(
      "YYYY-MM-DD HH:mm:ss"
    );
    const newPreviousFollowUpDate = currentDateToUtcDate(newDemoStartDate);
    const newNextFollowupDate = currentDateToUtcDate(newDemoEndDate);
    const contactNumber = mobileno !== "" ? mobileno : null;
    const currentDateAndTime = currentRegister();
    const newCurrentDate = currentDateToUtcDate(currentDateAndTime);
    const customerEmail = email !== "" ? email : null;
    const customerData = {};
    if (customerId) {
      customerData.id = getCustomerId();
    } else {
      customerData.branchId = getBranchId();
      customerData.name = name;
      // customerData.homeCountry = "LOCAL";
      customerData.mobile = contactNumber;
      customerData.email = customerEmail;
      customerData.enquirySource = "WEB";
      customerData.crmStatus = "ENGAGING";
      customerData.enrollmentDate = newCurrentDate;
      customerData.customerAddress = {
        latitude: Latitude ? Latitude : 0,
        longtitude: Longitude ? Longitude : 0,
      };
      customerData.countryCode = "+91";
      customerData.customerType = "CUSTOMER";
      customerData.homeCountry = "LOCAL";
    }

    let productId = selectedProduct ? selectedProduct.id : null;
    const enquiryData = {
      customer: customerData,
      enquirySourceId: EnquieryId[0].id,
      enquiryStatusId: EnquieryStatusId,
      enqGeneratedById: EmployeeId,
      productId: selectedProduct !== "" ? productId : null,
      prevFollowUpDate: newPreviousFollowUpDate,
      nextFollowUpDate: newNextFollowupDate,
      enquiryTypeId: EnquieryTypeid,
      reqDetails: enquiry !== "" ? enquiry : textarea,
      branchId: getBranchId(),
      languageId: languageid !== null ? languageid : null,
      metalTypeid: metalTypeid !== null ? metalTypeid : null,
    };
    createEnquiry(JSON.stringify(enquiryData))
      .then((data) => {
        toast.success("Your Appointment Created Successfully...!", {
          duration: 2000,
          position: "top-right",
        });
        setLoading(false);
        setValidInput(false);
        setName("");
        setmobileno("");
        setemail("");
        setDuration("");
        setEnquiry("");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          "You have already booked an appointment for this product...!",
          {
            duration: 2000,
            position: "top-right",
          }
        );
      });
  };

  return (
    <>
      <Toaster position="top-right" />
      {banner && banner.length > 0 ? (
        <img src={banner} alt="Book An Enquiry" className="w-100" />
      ) : (
        <Skeleton tw="w-full" style={{ height: "200px" }} />
      )}
      <Heading>Book An {selectedProduct ? "Enquiry" : "Appointment"}</Heading>
      <div className="ptSans px-sm-5 px-0" tw="text-sm pt-3">
        <Link to="/" tw="mx-3 no-underline text-black">
          Home
        </Link>
        /
        <span tw="ml-3 text-custom-100 capitalize tracking-wide ">
          Book An {selectedProduct ? "Enquiry" : "Appointment"}
        </span>
      </div>
      <section tw="lg:my-10 lg: pb-10 my-0">
        <Container>
          <div className="container">
            <div className="row justify-content-center  mx-auto">
              <div className="col-lg-6 order-2 order-lg-1">
                {selectedProduct && (
                  <div className="col-md-12  my-sm-0 my-4">
                    <div className="px-1" tw="py-2">
                      <b tw="text-custom-100 tracking-wide"> Product Details</b>
                    </div>
                    <div
                      className="max-w-md mx-auto bg-white  rounded-lg md:max-w-xl mx-2 p-3 rounded"
                      tw="border-2 border-custom-100"
                    >
                      <div className="row align-items-center justify-content-center">
                        <div className="col-6 col-md-4">
                          <img
                            src={
                              selectedProduct.images === "@#@"
                                ? Noimg
                                : selectedProduct.images.split("@#@")[0]
                            }
                            alt="Zinger Jewellers product img"
                            className="w-100 my-md-0 mb-3 rounded"
                          />
                        </div>
                        <div className="col-12 col-md-8">
                          <div className="row">
                            <h5 tw="text-custom-100">
                              {selectedProduct.category}
                            </h5>
                            <div className="col-6 col-md-4">
                              <p className="d-flex justify-content-between">
                                Metal <small>:</small>
                              </p>
                              <p className="d-flex justify-content-between">
                                Code <small>:</small>
                              </p>
                              <p className="d-flex justify-content-between">
                                Qty <small>:</small>
                              </p>
                              <p className="d-flex justify-content-between">
                                Total <small>:</small>
                              </p>
                            </div>
                            <div className="col-6 col-md-8">
                              <p>
                                {selectedProduct.goldPurity}{" "}
                                {selectedProduct.metalType}
                              </p>
                              <p>{selectedProduct.productCode}</p>
                              <p>1</p>
                              <b>{Math.trunc(selectedProduct.total)}</b>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <h4 tw="text-custom-100  pt-5 pb-3 mt-4 sm:(py-5 m-0)">
                  Here's how it works ?
                </h4>
                <ul tw=" list-disc pl-5 space-y-6">
                  <li>
                    Fill the details in the form pertaining to the store you
                    want to visit with a convenient date and time of your
                    choice.
                  </li>
                  <li>
                    Customer Care will Confirm your Appointment through Phone
                    Call / SMS
                  </li>
                  <li>
                    At the store, our store Manager will personally assist you
                    to choose from a range of handpicked designs kept aside as
                    per your preference Safety is our prime concern, hence we
                    ensure to take utmost care in Jewellery handling, we have
                    ensured to sanitize the Jewellery pieces before and after
                    any trails. While you are at our premises, be assured to
                    takeaway a personalized and safe shopping experience.
                  </li>
                </ul>
                <div className="row pt-5 ">
                  <div className="col-md-4 ">
                    <img
                      src={form}
                      alt="fill up form"
                      className="w-50 m-auto spinner"
                    />
                  </div>
                  <div className="col-md-4">
                    <img
                      src={custCare}
                      alt="customer Care"
                      className="w-50  m-auto spinner"
                    />
                  </div>
                  <div className="col-md-4">
                    <img
                      src={jewel}
                      alt="shopping experience"
                      className="w-50  m-auto spinner"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2">
                <form className="p-0 p-sm-4" tw="sm:shadow-raised">
                  <div className={`${customerId ? "d-none" : "d-block"}`}>
                    <div tw="my-5">
                      <div tw="relative">
                        <div tw="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                          <i className="bi bi-person" tw="text-xl"></i>
                        </div>
                        <input
                          type="text"
                          id="email-enquiry-icon"
                          value={name}
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                          tw="focus:outline-none focus:border-custom-100
                              focus:ring-1   bg-gray-200 border border-gray-300 
                              text-gray-900 text-sm rounded-lg  block w-full px-10 py-4"
                          placeholder="Enter Your Name"
                          className={`${
                            name === "" && validInput
                              ? "border border-danger"
                              : ""
                          }`}
                        />
                        {name === "" && validInput ? (
                          <div tw="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                            <i className="bi bi-exclamation-circle text-danger"></i>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      {name === "" && validInput ? (
                        <p tw="text-red-500 mt-1 mb-0 mx-5">
                          Enter Your Username
                        </p>
                      ) : (
                        " "
                      )}
                    </div>
                    <div tw="my-5">
                      <div tw="relative">
                        <div tw="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                          <i className="bi bi-phone" tw="text-xl"></i>
                        </div>
                        <input
                          type="text"
                          id="email-enquiry-icon"
                          value={mobileno.replace(/\s+/g, "")}
                          onChange={(e) => {
                            setmobileno(e.target.value);
                          }}
                          tw="focus:outline-none focus:border-custom-100
                              focus:ring-1   bg-gray-200 border border-gray-300 
                              text-gray-900 text-sm rounded-lg  block w-full px-10 py-4"
                          placeholder="Enter Your Mobile Number "
                          className={`${
                            (mobileno === "" && validInput) ||
                            invalidMobile ||
                            availableMobile
                              ? "border border-danger"
                              : ""
                          }`}
                        />
                        {(mobileno === "" && validInput) ||
                        invalidMobile ||
                        availableMobile ? (
                          <div tw="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                            <i className="bi bi-exclamation-circle text-danger"></i>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      {availableMobile !== "" && (
                        <p tw="text-red-500 mt-1 mb-0 mx-5">
                          {availableMobile}
                        </p>
                      )}

                      {invalidMobile && mobileno !== "" && (
                        <p tw="text-red-500 mt-1 mb-0 mx-5">{invalidMobile}</p>
                      )}
                      {mobileno === "" && validInput && (
                        <p tw="text-red-500 mt-1 mb-0 mx-5">
                          Enter Your Mobile Number
                        </p>
                      )}
                    </div>
                    <div tw="my-5">
                      <div tw="relative">
                        <div tw="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                          <i className="bi bi-envelope" tw="text-xl"></i>
                        </div>
                        <input
                          type="text"
                          id="email-enquiry-icon"
                          value={email.replace(/\s+/g, "")}
                          onChange={(e) => {
                            setemail(e.target.value);
                          }}
                          tw="focus:outline-none focus:border-custom-100
                              focus:ring-1   bg-gray-200 border border-gray-300 
                              text-gray-900 text-sm rounded-lg  block w-full px-10 py-4"
                          placeholder="Enter Your Email"
                          className={`${
                            (email === "" && validInput) || invalidEmail
                              ? "border border-danger"
                              : ""
                          }`}
                        />
                        {(email === "" && validInput) || invalidEmail ? (
                          <div tw="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                            <i className="bi bi-exclamation-circle text-danger"></i>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      {invalidEmail && email !== "" && (
                        <p tw="text-red-500 mt-1 mb-0 mx-5">{invalidEmail}</p>
                      )}

                      {email === "" && validInput ? (
                        <p tw="text-red-500 mt-1 mb-0 mx-5">Enter Your Email</p>
                      ) : (
                        " "
                      )}
                    </div>
                  </div>

                  <div tw="my-5">
                    <div tw="relative">
                      <div tw="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <i className="bi bi-file-lock2" tw="text-xl"></i>
                      </div>
                      {languageid === "" && validInput && (
                        <div tw="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                          <i className="bi bi-exclamation-circle text-danger"></i>
                        </div>
                      )}
                      <select
                      defaultValue={currentlang[0]?.title}
                        tw="focus:outline-none focus:border-custom-100
                        focus:ring-1   bg-gray-200 border
                         text-sm rounded-lg  block w-full px-10 py-4"
                        onChange={(e) => setLanguageid(e.target.value)}
                        className={`${
                          languageid === "" &&
                          validInput &&
                          "border border-danger"
                        }`}
                      >
                        <option value="select language preferred">
                          Select Language
                        </option>
                        {language.map((option, index) => (
                          <>
                            <option key={index} value={option.value}>
                              {option.title}
                            </option>
                          </>
                        ))}
                      </select>
                    </div>
                    {languageid === "" && validInput ? (
                      <p tw="text-red-500 mt-1 mx-5 mb-0">
                        Select Your language
                      </p>
                    ) : (
                      " "
                    )}
                  </div>
                  <div tw="my-5">
                    <div tw="relative">
                      <div tw="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <i className="bi bi-file-lock2" tw="text-xl"></i>
                      </div>
                      {metalTypeid === "" && validInput && (
                        <div tw="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                          <i className="bi bi-exclamation-circle text-danger"></i>
                        </div>
                      )}
                      <select
                        tw="focus:outline-none focus:border-custom-100
                        focus:ring-1   bg-gray-200 border
                         text-sm rounded-lg  block w-full px-10 py-4"
                        onChange={(e) => setMetalTypeid(e.target.value)}
                        value={metalTypeid}
                        className={`${
                          metalTypeid === "" &&
                          validInput &&
                          "border border-danger"
                        }`}
                      >
                        <option value="select metaltype preferred">
                          Select Metal Type
                        </option>
                        {metalTypeList.map((option, index) => (
                          <>
                            <option key={index} value={option.value}>
                              {option.title}
                            </option>
                          </>
                        ))}
                      </select>
                    </div>
                    {metalTypeid === "" && validInput ? (
                      <p tw="text-red-500 mt-1 mx-5 mb-0">
                        Select Your Metal Type
                      </p>
                    ) : (
                      " "
                    )}
                  </div>
                  {selectedProduct?.status?.toLowerCase() === "sold" ? null : 
                  <>
                  <div tw="my-5">
                    <div tw="relative">
                      <div tw="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"></div>

                      <input
                        type="datetime-local"
                        id="email-enquiry-icon"
                        value={scheduleDate}
                        onChange={(e) => {
                          setScheduleDate(e.target.value);
                        }}
                        min={scheduleDate}
                        tw="focus:outline-none focus:border-custom-100
                              focus:ring-1   bg-gray-200 border border-gray-300 
                              text-gray-900 text-sm rounded-lg  block w-full px-5 py-4"
                        placeholder="Schedule a Video Demo for me with Video Live at"
                        className={`${
                          scheduleDate === "" || !validTime
                            ? "border border-danger"
                            : ""
                        }`}
                      />
                      {scheduleDate === "" ? (
                        <div tw="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                          <i className="bi bi-exclamation-circle text-danger"></i>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {scheduleDate === "" ? (
                      <p tw="text-red-500 mt-1 mb-0 mx-5">
                        Select Your Schedule Demo Date
                      </p>
                    ) : (
                      " "
                    )}
                    {validTime === false ? (
                      <p tw="text-red-500 mt-1 mb-0 mx-5">
                        Please Select Valid Time
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div tw="my-5">
                    <div tw="relative">
                      <div tw="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <i className="bi bi-file-lock2" tw="text-xl"></i>
                      </div>
                      <select
                        tw="focus:outline-none focus:border-custom-100
                        focus:ring-1   bg-gray-200 border border-gray-300 
                        text-gray-900 text-sm rounded-lg  block w-full px-10 py-4"
                        placeholder="Select Your Duration"
                        onChange={(e) => {
                          setDuration(e.target.value);
                        }}
                      >
                        <option value="select user">
                          Select Duration (mins)
                        </option>
                        <option value="20">20</option>
                        <option value="30" selected>
                          30
                        </option>
                        <option value="40">40</option>
                        <option value="50">50</option>
                        <option value="60">60</option>
                      </select>
                    </div>
                  </div>
                  <div tw="my-5">
                    <div tw="relative">
                      <div tw="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"></div>
                      <textarea
                        type="text"
                        id="email-enquiry-icon"
                        // value={enquiry}
                        placeholder={textarea}
                        value={enquiry}
                        onChange={(e) =>
                          updateEnquiryDetailState(e.target.value)
                        }
                        tw="focus:outline-none focus:border-custom-100
                              focus:ring-1   bg-gray-200 border border-gray-300 
                              text-gray-900 text-sm rounded-lg  block w-full px-5 py-4"
                      />
                    </div>
                  </div>
                  </>
                  }
                  <button
                    type="button"
                    tw=" duration-500 transition w-full text-white bg-custom-100 hover:(bg-custom-200 text-black ) active:bg-custom-100 border-custom-100 border-dashed border focus:outline-none   rounded-lg text-sm p-5 text-center uppercase"
                    onClick={submitForm}
                    disabled={Loading ? true : ""}
                    className={`${Loading ? "bg-secondary" : ""}`}
                  >
                    {Loading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="mx-2">Loading . . .</span>
                      </>
                    ) : (
                      <>
                        <i
                          className="bi bi-box-arrow-in-right"
                          tw="mr-3 px-3 text-base"
                        ></i>
                        Submit
                      </>
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};
