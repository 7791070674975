import axios from "axios";
import { BASE_URL } from "../utility/UrlConstants";
import { getHeaderConfig } from "../utility/HeaderConfig";

const schemeType = (filters: Object) => {
  return axios
    .get(
      `${BASE_URL}/savingsscheme/savingsschemetype/get-page?page=0&size=20&sort=createdAt,desc&filters=${JSON.stringify(
        filters
      )}`,
      getHeaderConfig()
    )
    .then((response) => {
      const respData = {
        responseCode: response.status,
        response: response.data,
      };
      return respData;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

const schemeDetails = (searchData: String) => {
  return axios
    .get(
      `${BASE_URL}/savingsscheme/schememetadata/metadata/get-page?page=0&size=500&filters=${JSON.stringify(searchData)}`,
      getHeaderConfig()
    )
    .then((response) => {
      const respData = {
        responseCode: response.status,
        response: response.data,
      };
      return respData;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

const schemeStatus = (filters: Object) => {
  return axios
    .get(
      `${BASE_URL}/savingsscheme/customersavingdetails/get-page?page=0&size=50&sort=createdAt,desc&chitEnrolDtlsFilter=${JSON.stringify(
        filters
      )}`,
      getHeaderConfig()
    )
    .then((response) => {
      const respData = {
        responseCode: response.status,
        response: response.data,
      };
      return respData;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

const joinScheme = (requestBody: string) => {
  return axios
    .post(`${BASE_URL}/savingsscheme/customersavingdetails/create`, requestBody, getHeaderConfig())
    .then((response) =>  {
        const respData = {
          responseCode: response.status,
          response: response.data
        };
        return respData;
      })
    .catch((error) => {
      console.log(error);
      return []; // Return empty array in case error response.
    });
};

const dayClose = (filters: Object) => {
  return axios
    .get(
      `${BASE_URL}/admin/corporate/dayclose/get-all?filters=${JSON.stringify(
        filters
      )}`,
      getHeaderConfig()
    )
    .then((response) => {
      const respData = {
        responseCode: response.status,
        response: response.data,
      };
      return respData;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

const getChitBenefitValueData = (chitTypeId, enrollmentId, chitAmount) => {
  return axios
    .get(
      `${BASE_URL}/savingsscheme/chitcustomerpayment/tech-wallet/get-benefit-value?chitTypeId=${chitTypeId}&chitEnrollmentId=${enrollmentId}&chitAmount=${chitAmount}`,
      getHeaderConfig()
    )
    .then((response) => {
      const respData = {
        responseCode: response.status,
        response: response.data,
      };
      return respData;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

const getCustomerSchemeById = (filter : Object) =>{
  return axios.get(`${BASE_URL}/savingsscheme/customersavingdetails/get-all?chitEnrolDtlsFilter=${JSON.stringify(filter)}`,getHeaderConfig())
  .then((response) => {
    const respData = {
      responseCode : response.status,
      response : response.data,
    };
    return respData;
  })
  .catch((error) =>{
    console.log(error);
    throw error
  });
};

export {
  schemeType,
  schemeDetails,
  schemeStatus,
  joinScheme,
  dayClose,
  getChitBenefitValueData,
  getCustomerSchemeById
};
