import React, { useEffect, useRef, useState } from "react";
import tw from "twin.macro";
import { createAddress, updateAddress } from "api/AddressApi";
import toast from "react-hot-toast";
import { getCustomerId } from "utility/HeaderConfig";
import { useHistory,useLocation } from "react-router-dom/cjs/react-router-dom.min";
//eslint-disable-next-line
const StyledDiv = tw.div`font-display overflow-hidden`;
//eslint-disable-next-line
const BgColor = tw.div`bg-zinc-100 py-4 sm:py-10`;

const getInitialState = () => {
  const initialState = {
    customerId: getCustomerId(),
    customerName: "",
    mobileNo: "",
    pincodeNumber: "",
    stateName: "",
    address2: "",
    address1: "",
    latitude: "",
    longitude: "",
    landmark: "",
    districtName: "",
    addressId: "",
    isButtonPressed: false,
  };
  return initialState;
};

export default function AddAddress(props) {
  const history = useHistory();
  const location = useLocation();
  const parm = location.state;
  const viewAddressElemnt = useRef()
  const [state, setState] = useState(getInitialState());
  const [MobileError, setMobileError] = useState(false);
  const [errorUser, setErrorUser] = useState(false);

  const updateFieldState = (fieldName, fieldValue) => {
    setState((prev) => ({ ...prev, [fieldName]: fieldValue }));
  };

  useEffect(()=>{
    setState((prev)=>({
      ...prev,
      ...parm
    }));
    //eslint-disable-next-line
  },[parm]);

  const validateField = () => {
    const mobileReg = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (!mobileReg.test(state.mobileNo) && state.mobileNo !== "") {
      setMobileError(true);
    } else {
      setMobileError(false);
    }
    if (mobileReg.test(state.mobileNo) && state.address1 && state.pincodeNumber) {
      return true
    }
    else {
      setErrorUser(true);
    }
  }

  const postAjaxRequest = () => {
    if(validateField()){
    const {
      customerId,
      customerName,
      mobileNo,
      pincodeNumber,
      stateName,
      address2,
      address1,
      // latitude,
      // longitude,
      landmark,
      districtName,
    } = state;
    setState((prev) => ({ ...prev, isButtonPressed: true }));
    const createAddressData = JSON.stringify({
      customerId: customerId,
      customerName: customerName,
      mobileNo: mobileNo,
      pincodeNumber: pincodeNumber,
      stateName: stateName,
      address2: address2,
      address1: address1,
      // latitude : latitude,
      // longitude :longitude,
      landmark: landmark,
      districtName: districtName,
    });
    const respData = createAddress(createAddressData);
    respData
      .then(
        //eslint-disable-next-line
        (data) => {
          toast.success("Address Created Successfully..!", {
            position: "top-right",
          });
          setTimeout(() => {
            history.push("/CheckoutPage")
          }, 1000);
          setState(getInitialState())
          if (viewAddressElemnt && viewAddressElemnt.current) return viewAddressElemnt.current.click()

        }
      )
      .catch((err) => {
        // toast.error(err.response.data.displayMessage, {
        //   position: "top-right",
        // });
        setState((prev) => ({ ...prev, isButtonPressed: false }));
      });
    }
  };

  const updateAjaxRequest = () => {
    if(validateField()){
    const {
      addressId,
      customerName,
      mobileNo,
      pincodeNumber,
      stateName,
      address2,
      address1,
      latitude,
      longitude,
      landmark,
      districtName,
    } = state;
    setState((prev) => ({ ...prev, isButtonPressed: true }));
    const createAddressData = JSON.stringify({
      id:addressId ? addressId : parm.id ,
      customerName,
      mobileNo,
      pincodeNumber,
      stateName,
      address2,
      address1,
      latitude,
      longitude,
      landmark,
      districtName,
    });
    const respData = updateAddress(createAddressData);
    respData
      .then(
        //eslint-disable-next-line
        (data) => {
          toast.success("Address Updated Successfully..!", {
            position: "top-right",
          });
          setTimeout(() => {
            history.push("/CheckoutPage")
          }, 1000);
          setState(getInitialState())
          if (viewAddressElemnt && viewAddressElemnt.current) return viewAddressElemnt.current.click()

        }
      )
      .catch((err) => {
        // toast.error(err.response.data.displayMessage, {
        //   position: "top-right",
        // });
        setState((prev) => ({ ...prev, isButtonPressed: false }));
      });
    }
  };


  return (
    <div>
          <div className="container">
            <div className="row my-sm-5 my-3">
              <div className="col-md-8">
                <div className="bg-gray-300">
                  <div className="py-12">
                    <div className="max-w-md mx-auto bg-white shadow-lg rounded-lg md:max-w-xl mx-2">
                      <div
                        className="px-5"
                        tw="py-2 border-b-4 border-custom-100"
                      >
                        <b>Add Address</b>
                      </div>
                      <div className="px-sm-5 py-2 px-2 py-sm-4">
                        <form className="needs-validation">
                          <div className="row mb-3">
                            <div className="col-md-3 d-flex justify-content-between align-items-center">
                              <label
                                for="inputPassword6"
                                className="col-form-label"
                              >
                                Name
                              </label>
                              <small className="text-right d-sm-block d-none">
                                :
                              </small>
                            </div>
                            <div className="col-md-8">
                              <input
                                type="text"
                                className={`form-control w-100 py-2  ${state.customerName === '' && errorUser ? 'border-danger' : ''}`}
                                id="customerName"
                                value={state.customerName}
                                // tw="focus:outline-none focus:border-custom-100
                                // focus:ring-1 text-sm "
                                placeholder="Enter Your Name"
                                onChange={(e) =>
                                  updateFieldState(
                                    "customerName",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-md-3 d-flex justify-content-between align-items-center">
                              <label
                                for="inputPassword6"
                                className="col-form-label"
                              >
                                Mobile Number
                              </label>
                              <small className="text-right d-sm-block d-none">
                                :
                              </small>
                            </div>
                            <div className="col-md-8">
                              <input
                                type="text"
                                className={`form-control w-100 py-2  ${(state.mobileNo === '' && errorUser) || MobileError ? 'border-danger' : ''} `}
                                // tw="focus:outline-none focus:border-custom-100
                                // focus:ring-1  
                                //     text-sm"
                                id="mobileNo"
                                value={state.mobileNo}
                                placeholder="Enter Mobile Number "
                                onChange={(e) =>
                                  updateFieldState("mobileNo", e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-md-3 d-flex justify-content-between align-items-center">
                              <label
                                for="inputPassword6"
                                className="col-form-label"
                              >
                                Address
                              </label>
                              <small className="text-right d-sm-block d-none">
                                :
                              </small>
                            </div>
                            <div className="col-md-8">
                              <textarea
                                className={`form-control w-100 py-2  ${state.address1 === '' && errorUser ? 'border-danger' : ''}`}
                                id="address2"
                                // tw="focus:outline-none focus:border-custom-100
                                // focus:ring-1  
                                // text-sm"
                                placeholder="Enter Address "
                                rows={2}
                                value={state.address1}
                                onChange={(e) =>
                                  updateFieldState("address1", e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-md-3 d-flex justify-content-between align-items-center">
                              <label
                                for="inputPassword6"
                                className="col-form-label"
                              >
                                Landmark
                              </label>
                              <small className="text-right d-sm-block d-none">
                                :
                              </small>
                            </div>
                            <div className="col-md-8">
                              <input
                                type="text"
                                className={`form-control w-100 py-2  ${state.landmark === '' && errorUser ? 'border-danger' : ''}`}
                                // tw="focus:outline-none focus:border-custom-100
                                // focus:ring-1  
                                //     text-sm"
                                id="landmark"
                                placeholder="Enter Landmark "
                                value={state.landmark}
                                onChange={(e) =>
                                  updateFieldState("landmark", e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-md-3 d-flex justify-content-between align-items-center">
                              <label
                                for="inputPassword6"
                                className="col-form-label"
                              >
                                Zip / Postal Code{" "}
                              </label>
                              <small className="text-right d-sm-block d-none">
                                :
                              </small>
                            </div>
                            <div className="col-md-8">
                              <input
                                type="text"
                                className={`form-control w-100 py-2  ${state.pincodeNumber === '' && errorUser ? 'border-danger' : ''}`}
                                // tw="focus:outline-none focus:border-custom-100
                                // focus:ring-1  
                                //     text-sm"
                                id="pincodeNumber"
                                placeholder="Enter Postal Code"
                                value={state.pincodeNumber}
                                onChange={(e) =>
                                  updateFieldState(
                                    "pincodeNumber",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-md-3 d-flex justify-content-between align-items-center">
                              <label
                                for="inputPassword6"
                                className="col-form-label"
                              >
                                District
                              </label>
                              <small className="text-right d-sm-block d-none">
                                :
                              </small>
                            </div>
                            <div className="col-md-8">
                              <input
                                type="text"
                                className={`form-control w-100 py-2  ${state.districtName === '' && errorUser ? 'border-danger' : ''}`}
                                // tw="focus:outline-none focus:border-custom-100
                                // focus:ring-1  
                                //     text-sm"
                                id="districtName"
                                placeholder="Enter City "
                                value={state.districtName}
                                onChange={(e) =>
                                  updateFieldState(
                                    "districtName",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-md-3 d-flex justify-content-between align-items-center">
                              <label
                                for="inputPassword6"
                                className="col-form-label"
                              >
                                State
                              </label>
                              <small className="text-right d-sm-block d-none">
                                :
                              </small>
                            </div>
                            <div className="col-md-8">
                              <input
                                type="text"
                                className={`form-control w-100 py-2  ${state.stateName === '' && errorUser ? 'border-danger' : ''}`}
                                // tw="focus:outline-none focus:border-custom-100
                                // focus:ring-1  
                                //     text-sm"
                                id="stateName"
                                placeholder="Enter State "
                                value={state.stateName}
                                onChange={(e) =>
                                  updateFieldState("stateName", e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <hr className="mb-3" />
                          <div className="text-center">
                           
                              {state?.addressId || parm?.id ?
                               <button
                               className="btn-md hoverEffect px-3 py-2 rounded"
                               onClick={ updateAjaxRequest}
                               type="button"
                               disabled={state.isButtonPressed}
                             >
                               Update
                             </button>
                                :
                                <button
                                className="btn-md hoverEffect px-3 py-2 rounded"
                                onClick={postAjaxRequest}
                                type="button"
                                disabled={state.isButtonPressed}
                              >
                                Save
                              </button>
                                }
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>
  );
}
