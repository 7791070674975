import tw from "twin.macro";
import styled from "styled-components";
import { schemeDetails, schemeStatus } from "api/NewSchemeApi";
import { branchdetails } from "api/BranchDetailsApi";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg/svg-decorator-blob-7.svg";
import Noimg from "../images/no_img.jpg";
import { getBranchId, getCustomerId } from "../utility/HeaderConfig.js";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import { dataList } from "api/SkeletonData";
import Skeleton from "react-loading-skeleton";
import { useCartContext } from "context/UseCartContext";

const Container = tw.div`relative font-display overflow-hidden`;
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-custom-100`}
`;

export default ({ configImages }) => {
  const [SchemeDetails, setSchemeDetails] = useState([]);
  const [showModal, setshowModal] = useState(false);
  const [BranchMobile, setBranchMobile] = useState("");
  const [loading, setLoading] = useState(true);
  const [banner, setBanner] = useState([]);
  const [isReadMore, setIsReadMore] = useState(false);

  const history = useHistory();

  useEffect(() => {
    getSchemeDetail();
    getBanner();
    //eslint-disable-next-line
  }, []);

  const { dispatch } = useCartContext();

  const getBanner = () => {
    configImages && configImages.E_COMMERCE_BANNERS.forEach((data) => {
      if (data.key === "schemeDetails") {
        setBanner(data.value);
      }
    });
  };

  const location = useLocation();
  const myparam = location.state;

  const getSchemeDetail = () => {
    setLoading(true);
    let searchData = {
      chitType: myparam,
      branchDetailsEntity: getBranchId(),
    };
    schemeDetails(searchData).then(
      (data) => {
        setSchemeDetails(data.response.content);
        setLoading(false);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const schemeCheck = (itemName, item) => {
    let requestName = itemName.toLowerCase() === "digigold" || itemName.toLowerCase() === "digisilver" ? "Active" : "Waiting_For_Approval";
    if (getCustomerId() === undefined) {
      dispatch({ type: "MODAL", payload: "login" });
    } else {
      if (
        getCustomerId() &&
        getCustomerId() !== null &&
        getCustomerId() !== "onlinepayment"
      ) {
        schemeStatus({
          customerEntity: getCustomerId(),
          chitEnrollStatus: requestName,
        }).then((data) => {
          console.log(data);
          const show = data.response.content.filter(
            (item) => item.metaChitName === itemName
          );
          if (show.length > 0) {
            setshowModal(true);
            getbranchdetails();
          } else {
            history.push("/JoinScheme", item);
          }
        });
      }
    }
  };

  const getbranchdetails = () => {
    branchdetails(getBranchId()).then((data) => {
      setBranchMobile(data.response.mobile);
    });
  };

  const toggleReadMore = (id) => {
    setIsReadMore(id);
  };

  return (
    <Container>
      {banner && banner.length > 0 ? (
        <img src={banner} alt="Saving Scheme Details" className="w-100" />
      ) : (
        <Skeleton tw="w-full" style={{ height: "200px" }} />
      )}
      <section tw="py-10 relative " className="custom_background">
        <div className="container container-influid-md">
          <div className="row">
            {SchemeDetails.length > 0 ? (
              SchemeDetails.map((item) => (
                <div className="col-md-6 col-lg-4 col-xl-4 py-3">
                  <div className="rounded border" tw="hover:shadow-lg">
                    <img
                      src={
                        item.chitImage !== null &&
                        item.chitImage !== "" &&
                        item.chitImage !== "-" &&
                        item.chitImage !== "Image_Not_Available"
                          ? item.chitImage
                          : Noimg
                      }
                      alt="schemeImage"
                      className="w-100 rounded"
                      style={{ height: "261px" }}
                    />
                    <div className="p-3" tw="bg-custom-200">
                      <h6 tw="text-base text-center text-custom-100 tracking-wider font-bold mb-2 uppercase">
                        {item.chitTypeName} ( {item.chitType} )
                      </h6>

                      {item.description !== "" || item.description !== "-" ? (
                        <p tw="mb-2 text-xs text-justify ">
                          <p className="mb-1  text-center" tw="font-semibold">
                            Description
                          </p>
                          {item.description !== ""
                            ? isReadMore === item.id
                              ? item.description
                              : item.description.slice(0, 150)
                            : "No Description"}
                          {item.description !== "" &&
                          item.description.length > 150 ? (
                            isReadMore === item.id ? (
                              <span
                                tw="cursor-pointer font-bold hover:text-custom-100"
                                onClick={() => toggleReadMore(false)}
                              >
                                ....Show Less <i className="bi bi-caret-up"></i>
                              </span>
                            ) : (
                              <span
                                tw="cursor-pointer font-bold hover:text-blue-700"
                                onClick={() => toggleReadMore(item.id)}
                              >
                                ....Read More{" "}
                                <i className="bi bi-caret-down"></i>
                              </span>
                            )
                          ) : (
                            ""
                          )}
                        </p>
                      ) : (
                        ""
                      )}
                      <table className="col-md-6 w-100 ptSans">
                        <tr tw="leading-7">
                          <td tw="px-3">Group Code</td>:
                          <td tw="px-3">{item.name}</td>
                        </tr>
                        <tr tw="leading-7">
                          <td tw="px-3">Due Amount</td>:
                          <td tw="px-3">
                            {item.chitAmount > 0
                              ? item.chitAmount
                              : "Flexible Amt"}
                          </td>
                        </tr>
                        <tr tw="leading-7">
                          <td tw="px-3">Duration</td>:
                          <td tw="px-3">
                            {item.noOfMonths}{" "}
                            {item.duration === "MONTH" ? "mts" : "Days"}
                          </td>
                        </tr>
                        {item.maturityAmount > 0 ? (
                          <tr tw="leading-7">
                            <td tw="px-3">Maturity Amount</td>:
                            <td tw="px-3">
                              {item.maturityAmount > 0
                                ? item.maturityAmount
                                : "-"}
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                      </table>

                      <div className=" col-12 my-lg-0 pt-3">
                        <div id="holder">
                          <div
                            className="button"
                            onClick={() => schemeCheck(item.name, item)}
                          >
                            <p className="btnText m-0 h-100 d-flex justify-content-center align-items-center rounded">
                              Join Scheme
                            </p>
                            <div className="btnTwo">
                              <p className="btnText2">
                                <i className="bi bi-patch-plus"></i>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              // <img src={no_data} alt="no-data" className="col-md-4 m-auto" />
              <p></p>
            )}
          </div>
          {loading && (
            <div className="row justify-content-center">
              {dataList.slice(0, 8).map((data) => (
                <div className="col-md-4 py-3">
                  <Skeleton tw="w-full" style={{ height: "300px" }} />
                  <div className="card my-2 text-center py-2">
                    <Skeleton className="px-3" tw="w-11/12" />
                    <Skeleton className="px-3" tw="w-11/12" />
                    <Skeleton className="px-3" tw="w-11/12" />
                    <Skeleton className="px-3" tw="w-11/12" />
                    <Skeleton className="px-3" tw="w-11/12" />
                    <Skeleton className="px-3" tw="w-11/12" />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <DecoratorBlob1 />
        <DecoratorBlob2 />
      </section>

      {showModal ? (
        <>
          <motion.div
            initial={{ x: "700px", opacity: 0, scale: 0.5 }}
            animate={{ x: 0, opacity: 1, scale: 1 }}
            tw="justify-center  flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div tw="relative w-auto my-20 mx-auto w-1/4">
              {/*content*/}
              <div tw="relative border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div tw="flex items-start justify-between items-center p-2 border-b border-solid border-custom-100 rounded-t">
                  <h6 tw="text-sm m-0 uppercase tracking-wide">Join Scheme</h6>
                  <button
                    tw="p-1 ml-auto  border-0 text-gray-400 float-right text-xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setshowModal(false)}
                  >
                    <i className="bi bi-x-circle"></i>
                  </button>
                </div>
                {/*body*/}
                <div tw="relative p-6 pb-3 flex-auto text-center">
                  <p>Already Joined in this Scheme & Status is Pending...</p>
                  <p tw="mb-1">Please Contact Customer Support</p>
                  <strong>{BranchMobile}</strong>
                </div>
                <div tw="text-center">
                  <button
                    type="button"
                    className="btn btn-dark w-25 border-0 "
                    tw="bg-custom-100 mb-4"
                    onClick={() => setshowModal(false)}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </motion.div>
          <div tw="opacity-25 fixed inset-0 z-40 bg-black "></div>
        </>
      ) : null}
    </Container>
  );
};
