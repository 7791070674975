import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import { pincodeAutoSearch, getPincodeHierarchy } from "api/AddressApi.js";
import { getBranchId, getCustomerId } from "utility/HeaderConfig";
import { ECommerce } from "api/AppConfigApi";
import { updateCustomerDetails } from "../api/CustomerApi";
import { getCustomer } from "api/CustomerApi";
import { getProductStatusData } from "api/ProductApi";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import ViewAddress from "./ViewAddress";

// eslint-disable-next-line
const Heading = tw.h1`text-center`;

export default function ShippingAddress(props) {
  const history = useHistory();

  const [verifyAmount,setVerifyAmount] =useState(0);
  const [errorUser, setErrorUser] = useState(false);
  const [MobileError, setMobileError] = useState(false);
  const [EmailError, setEmailError] = useState(false);
  const [pincode, setPincode] = useState(true);
  const [loading, setLoading] = useState(false);
  const [ propValueData, setPropVaueData]=useState(props.viewCart);
  const [checkStatus,setCheckStatus] =useState(false);
  const [Details, setDetails] = useState({
    name: "",
    mobile: "",
    email: "",
    address1: "",
    address2: "",
    pincode: "",
    pincodeNumber: "",
    landmark: "",
    countryName: "",
    districtName: "",
    district: "",
    stateName: "",
    state: "",
    county: "",
    customerId: "",
    customerCurrentCredit: 0,
    customerCurrentDebit: 0,
  });

  useEffect(() => {
    getCustomerDetails();
    getCheckProductStatus();
    getPriceCheck();
    //eslint-disable-next-line
  },[]);

  const getCustomerDetails = () => {
    const response = getCustomer(getCustomerId());
    response.then((data) => {
      setDetails({
        customerId: data.response.id,
        name: data.response.name,
        mobile: data.response.mobile,
        email: data.response.email,
        address1: data.response.customerAddress.address1,
        address2: data.response.customerAddress.address2,
        customerType : data.response.customerType,
        pincodeNumber: data.response.customerAddress.pincode,
        pincode: data.response.customerAddress.pincodeNumber !== null ? data.response.customerAddress.pincodeNumber : '',
        landmark: data.response.customerAddress.landmark !== '-' ? data.response.customerAddress.landmark : '',
        districtName: data.response.customerAddress.districtName !== null ? data.response.customerAddress.districtName : '',
        district: data.response.customerAddress.district,
        stateName: data.response.customerAddress.stateName !== null ? data.response.customerAddress.stateName : '',
        state:data.response.customerAddress.state,
        countryName: data.response.customerAddress.countryName !== null ? data.response.customerAddress.countryName : '',
        country : data.response.customerAddress.country,
        enrollmentDate : data.response.enrollmentDate !== null ?  data.response.enrollmentDate : null,
        customerCurrentDebit: data.response.debitValue,
        customerCurrentCredit: data.response.creditValue
      });
    });
  };

  //eslint-disable-next-line
  const updateFieldState = (e) => {
    const input = e.target.name;
    const value = e.target.value.trim();
    setDetails({ ...Details, [input]: value });
  };

  const getCheckProductStatus = () => {

    propValueData.forEach((element,idx) => {
      const res = getProductStatusData(element.id);
      res.then((data) =>{
      if(data.response.length > 0){
        data.response.forEach((item) =>{
          element['statusName']= item.status
          if(item.status.toLowerCase() === "sold"){          

            setCheckStatus(true)
              }
            });
          }
        })
    .catch((err) => {
          console.log(err);
        });
      if (propValueData.length -1 === idx) {
        setPropVaueData(propValueData);
      }
    });
  };
  const validateField = () => {
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;  //eslint-disable-line
    const mobileReg = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (!reg.test(Details.email) && Details.email !== "") {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
    if (!mobileReg.test(Details.mobile) && Details.mobile !== "") {
      setMobileError(true);
    } else {
      setMobileError(false);
    }
    if (reg.test(Details.email) && mobileReg.test(Details.mobile) && Details.address1 && Details.pincode) {
      return true
    }
    else {
      setErrorUser(true);
    }
  };

//eslint-disable-next-line
  const autoSearch = (searchValue) => {
    setDetails({ ...Details, pincode: searchValue })
    if (searchValue && searchValue.length > 4) {
      pincodeAutoSearch(searchValue).then((data) => {
        if (data.response.length > 0){
          setPincode(data.response);
        }
      });
    }
  };

//eslint-disable-next-line
  const pincodeId = (pincodeId) => {
    if (pincodeId)
      getPincodeHierarchy(pincodeId).then((data) => {
        setDetails({
          ...Details,
          pincode: data.response.code,
          landmark: data.response.landmark,
          districtName: data.response.districtName,
          stateName: data.response.stateName,
          countryName: data.response.countryName,
          country: data.response.countryId,
          district: data.response.districtId,
          state: data.response.stateId,
          pincodeNumber: data.response.id,
          enrollmentDate : data.response.enrollmentDate
        });
        setPincode(false);
      });
  };

  const AddressDetails = () => {
    if(props?.total <= verifyAmount){
    if(!checkStatus){  
    if (validateField()) {
      setLoading(true);
      const customerDetails = {
        branchId: getBranchId(),
        id: getCustomerId(),
        // enrollmentDate: currentRegister(),
        crmStatus: "ENGAGING",
        enquirySource: "WEB",
        name: Details.name,
        mobile: Details.mobile,
        email: Details.email,
        enrollmentDate : Details.enrollmentDate !==null ? Details.enrollmentDate : null ,
        customerType: Details.customerType,
        customerAddress: {
          longitude: 0,
          latitude: 0,
          landmark: Details.landmark,
          country: Details.country,
          countryName:Details.countryName,
          districtName:Details.districtName,
          stateName:Details.stateName ,
          district: Details.district,
          pincode: Details.pincodeNumber,
          state: Details.state,
          address1: Details.address1
            ? Details.address1
            : null
        }
      }
      const responseData = updateCustomerDetails(customerDetails);
      responseData.then((data) => {
        setLoading(false);
        props.onClick(data.response)
      })
    }
  }else{
    history.push({pathname:"/CartPage"
      ,state :{propValueData}} )
  }
 }else{
  toast.error(`The Purchase Amount Is Shouldn't Be Greater Than ${verifyAmount}`,{
    position: "top-right",
    duration: 3000,
   })
 }};

  const getPriceCheck = async () => {
    let filter = "sales_threshold";
    let appConfigResData = await ECommerce(filter);
    let appConfigthresholdValue = JSON.parse(
      appConfigResData.response.moduleField
    );
    let saleThresholdValue = 0;
    Object.entries(appConfigthresholdValue).forEach((entry) => {
      if (entry[0] === "sales_threshold_amount") {
        saleThresholdValue = entry[1];
      }
    });
    setVerifyAmount(saleThresholdValue);
  };

  return (
    // <form className="needs-validation">
    //   <div className="row mb-3">
    //     <div className="col-md-3 d-flex justify-content-between align-items-center">
    //       <label for="inputPassword6" className="col-form-label">
    //         Name
    //       </label>
    //       <small className="text-right d-sm-block d-none">:</small>
    //     </div>
    //     <div className="col-md-8">
    //       <input
    //         type="text"
    //         className={`form-control w-100 py-2 ${
    //           Details.name === "" && errorUser ? "border-danger" : ""
    //         }`}
    //         name="name"
    //         id="lastName"
    //         value={Details.name}
    //         tw="focus:outline-none focus:border-custom-100
    //                             focus:ring-1 text-sm "
    //         placeholder="Enter Your Name"
    //         onChange={updateFieldState}
    //       />
    //     </div>
    //   </div>
    //   <div className="row mb-3">
    //     <div className="col-md-3 d-flex justify-content-between align-items-center">
    //       <label for="inputPassword6" className="col-form-label">
    //         Mobile Number
    //       </label>
    //       <small className="text-right d-sm-block d-none">:</small>
    //     </div>
    //     <div className="col-md-8">
    //       <input
    //         type="text"
    //         className={`form-control w-100 py-2  ${
    //           (Details.mobile === "" && errorUser) || MobileError
    //             ? "border-danger"
    //             : ""
    //         }`}
    //         tw="focus:outline-none focus:border-custom-100
    //                             focus:ring-1  
    //                                 text-sm"
    //         id="mobile"
    //         name="mobile"
    //         value={Details.mobile}
    //         placeholder="Enter Mobile Number "
    //         onChange={updateFieldState}
    //       />
    //       {MobileError && (
    //         <small className="px-2 text-danger">
    //           Please Enter Valid Mobile Number
    //         </small>
    //       )}
    //     </div>
    //   </div>
    //   <div className="row mb-3">
    //     <div className="col-md-3 d-flex justify-content-between align-items-center">
    //       <label for="inputPassword6" className="col-form-label">
    //         Email Id{" "}
    //       </label>
    //       <small className="text-right d-sm-block d-none">:</small>
    //     </div>
    //     <div className="col-md-8">
    //       <input
    //         type="text"
    //         className={`form-control w-100 py-2  ${
    //           (Details.email === "" && errorUser) || EmailError
    //             ? "border-danger"
    //             : ""
    //         }`}
    //         tw="focus:outline-none focus:border-custom-100
    //                             focus:ring-1  
    //                                 text-sm"
    //         id="email"
    //         name="email"
    //         placeholder="Enter Email Id "
    //         value={Details.email}
    //         onChange={updateFieldState}
    //       />
    //       {EmailError && (
    //         <small className="px-2 text-danger">
    //           Please Enter Valid Email Id
    //         </small>
    //       )}
    //     </div>
    //   </div>
    //   <div className="row mb-3">
    //     <div className="col-md-3 d-flex justify-content-between align-items-center">
    //       <label for="inputPassword6" className="col-form-label">
    //                             Address
    //                           </label>
    //                           <small className="text-right d-sm-block d-none">:</small>
    //                         </div>
    //                         <div className="col-md-8">
    //                           <textarea
    //                             className={`form-control w-100 py-2  ${Details.address1 === "" && errorUser ? "border-danger" : ""}`}
    //                             id="Address"
    //                             name="address1"
    //                             tw="focus:outline-none focus:border-custom-100
    //                             focus:ring-1  
    //                                 text-sm"
    //                             rows={2}
    //                             value={Details.address1}
    //                             onChange={
    //                               updateFieldState
    //                             }
    //                           />
    //                         </div>
    //                       </div>
    //                       <div className="row mb-3">
    //                         <div className="col-md-3 d-flex justify-content-between align-items-center">
    //                           <label
    //                             for="inputPassword6"
    //                             className="col-form-label"
    //                           >
    //                             Landmark
    //                           </label>
    //                           <small className="text-right d-sm-block d-none">:</small>
    //                         </div>
    //                         <div className="col-md-8">
    //                           <input
    //                             type="text"
    //                             className={`form-control w-100 py-2  ${Details.landmark === "" && "-" && errorUser ? "border-danger" : ""}`}
    //                             tw="focus:outline-none focus:border-custom-100
    //                             focus:ring-1  
    //                                 text-sm"
    //                             id="landmark"
    //                             name="landmark"
    //                             placeholder="Enter Landmark "
    //                             value={
    //                               Details.landmark !== "-" ? Details.landmark : ''
    //                             }
    //                             onChange={
    //                               updateFieldState
    //                             }
    //                           />
    //                         </div>
    //                       </div>
    //                       <div className="row mb-3">
    //                         <div className="col-md-3 d-flex justify-content-between align-items-center">
    //                           <label for="inputPassword6" className="col-form-label">
    //                             Zip / Postal Code{" "}
    //                           </label>
    //                           <small className="text-right d-sm-block d-none">:</small>
    //                         </div>
    //                         <div className="col-md-8">
    //                           <input
    //                             type="text"
    //                             className={`form-control w-100 py-2   ${
    //                               Details.pincode === "" && errorUser ? "border-danger" : ""
    //                             }`}
    //                             tw="focus:outline-none focus:border-custom-100
    //                             focus:ring-1  
    //                                 text-sm"
    //                             id="pincode"
    //                             name="pincode"
    //                             placeholder="Enter Postal Code"
    //                             value={Details.pincode !== null && "-" ? Details.pincode : ""}
    //                             onChange={(e) => autoSearch(e.target.value)}
    //                           />
    //                           {pincode.length > 0 &&
    //                             <div className="card" tw="bg-custom-100 cursor-pointer">
    //                               <div className="card-body py-2 px-3 text-white" onClick={() => { pincodeId(pincode[0].id) }}>{pincode[0].code}</div>
    //                             </div>
    //                           }
    //                         </div>
    //                       </div>
    //                       <div className="row mb-3">
    //                         <div className="col-md-3 d-flex justify-content-between align-items-center">
    //                           <label
    //                             for="inputPassword6"
    //                             className="col-form-label"
    //                           >
    //                             District Name
    //                           </label>
    //                           <small className="text-right d-sm-block d-none">:</small>
    //                         </div>
    //                         <div className="col-md-8">
    //                           <input
    //                             type="text"
    //                             className={`form-control w-100 py-2  ${Details.districtName === '' && errorUser ? 'border-danger' : ''}`}
    //                             tw="focus:outline-none focus:border-custom-100
    //                             focus:ring-1  
    //                                 text-sm"
    //                             id="districtName"
    //                             name="districtName"
    //                             placeholder="Enter City "
    //                             value={
    //                               Details.districtName
    //                             }
    //                             onChange={
    //                               updateFieldState
    //                             }
    //                           />
    //                         </div>
    //                       </div>
    //                       <div className="row mb-3">
    //                         <div className="col-md-3 d-flex justify-content-between align-items-center">
    //                           <label
    //                             for="inputPassword6"
    //                             className="col-form-label"
    //                           >
    //                             State
    //                           </label>
    //                           <small className="text-right d-sm-block d-none">:</small>
    //                         </div>
    //                         <div className="col-md-8">
    //                           <input
    //                             type="text"
    //                             className={`form-control w-100 py-2  ${Details.stateName === '' && errorUser ? 'border-danger' : ''}`}
    //                             tw="focus:outline-none focus:border-custom-100
    //                             focus:ring-1  
    //                                 text-sm"
    //                             id="stateName"
    //                             name="stateName"
    //                             placeholder="Enter State "
    //                             value={
    //                               Details.stateName !== null && '-' ?
    //                                 Details.stateName : ''
    //                             }
    //                             onChange={
    //                               updateFieldState
    //                             }
    //                           />
    //                         </div>
    //                       </div>
    //                       <div className="row mb-3">
    //                         <div className="col-md-3 d-flex justify-content-between align-items-center">
    //                           <label
    //                             for="inputPassword6"
    //                             className="col-form-label"
    //                           >
    //                             Country{" "}
    //                           </label>
    //                           <small className="text-right d-sm-block d-none">:</small>
    //                         </div>
    //                         <div className="col-md-8">
    //                           <input
    //                             type="text"
    //                             className={`form-control w-100 py-2  ${Details.countryName === '' && errorUser ? 'border-danger' : ''}`}
    //                             tw="focus:outline-none focus:border-custom-100
    //                             focus:ring-1  
    //                                 text-sm"
    //                             id="countryName"
    //                             name="countryName"
    //                             placeholder="Enter Country "
    //                             value={
    //                               Details.countryName !== null && '-' ?
    //                                 Details.countryName : ''
    //                             }
    //                             onChange={
    //                               updateFieldState
    //                             }
    //                           />
    //                         </div>
    //                       </div>
    //                       <hr className="mb-3" />
    //                       <div className="text-center">
    //                         <button
    //                           className="btn-md hoverEffect px-3 py-2 rounded"
    //                           onClick={AddressDetails}
    //                           // disabled ={checkStatus ? true : ""}
    //                           type="button"
    //                         >
    //                           {loading ? (
    //                             <>
    //                               <span
    //                                 className="spinner-border spinner-border-sm"
    //                                 role="status"
    //                               ></span>
    //                               <span className="mx-2">Loading . . .</span>
    //                             </>
    //                           ) : (
    //                             <>
    //                               Save & Continue
    //                             </>
    //                           )}
    //                         </button>
    //                       </div>
    //                     </form>
    <>
       <button type="button" className="btn btn-success rounded-4" onClick={()=>{history.push("/AddAddress")}}> 
       <i className="bi bi-plus-lg"></i> Add Address
       </button> 
       <ViewAddress/>
       <div className="text-center">
         <button
           className="btn-md hoverEffect px-3 py-2 rounded"
           onClick={AddressDetails}
           disabled ={checkStatus ? true : ""}
           type="button"
         >
           {loading ? (
             <>
               <span
                 className="spinner-border spinner-border-sm"
                 role="status"
               ></span>
               <span className="mx-2">Loading . . .</span>
             </>
           ) : (
             <>
               Save & Continue
             </>
           )}
         </button>
       </div>
    </>
  );
}
