import React, { memo, useEffect, useState } from "react";
import  tw from "twin.macro";
import { ECommerce} from "../api/AppConfigApi";

//eslint-disable-next-line
const Heading = tw.h1`text-black`;

function ChoosePaymentMethod({Loading , OrderStatus ,createSaleItems}) {

  const [paymentModeLabel, setPaymentModeLabel] =useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [agreeTerms, setAgreeTerms] = useState(false);


  useEffect(()=>{
    getPaymentConfigurationData();
    //eslint-disable-next-line
  },[])

  const getPaymentConfigurationData = async() => {
    
  const appConfigResData = await  ECommerce("pg_endpoint_config");
  const paymentModuleList = JSON.parse(appConfigResData.response.moduleField);
  const ccAvenueData = paymentModuleList.paymentConfMap["Salem four roads"];  
  const paymentModes = Object.keys(ccAvenueData).filter(item => item === "CCAVENUE");

  // const paymentModes = []
  // Object.entries(abcd.paymentConfMap.Rasi).forEach((item)=>{
  //   paymentModes.push(item[0])
  // })  
  setPaymentModeLabel(paymentModes)
}

  const onRadioChange = (event) => {
    setSelectedPaymentMethod(event.target.value);
  };

  const onCheckboxChange = (event) => {
    setAgreeTerms(event.target.checked);
  };

  const validateForm = () => {
    return selectedPaymentMethod !== "" && agreeTerms;
  };

  const onSubmitForm = (event) => {
    event.preventDefault();
    OrderStatus(); 
  };
  
  return (
    <form className="needs-validation">
      <div className="row">
        <b>Choose Your Payment Method</b>
        <div
          className="form-check"
          tw="sm:mx-20 sm:my-10 m-5"
          // style={{ margin: " 30px 51px" }}
        >
            {paymentModeLabel.map((item, index) => (
            <div key={index}>
          <input
            className="form-check-input"
            type="radio"
                name="paymentMethod"
                id={`flexRadioDefault${index}`}
                value={item}
                checked={selectedPaymentMethod === item}
                onChange={onRadioChange}
              />
              <label className="form-check-label" htmlFor={`flexRadioDefault${index}`}>
                {item === "CCAVENUE" && item}
          </label>
            </div>
          ))}
        </div>
        <div className="form-check py-2 " tw="text-sm">
          <input
            className="form-check-input mx-sm-2 mx-sm-1"
            type="checkbox"
            name="flexRadioDefault"
            id="flexRadioDefault1"
            checked={agreeTerms}
            onChange={onCheckboxChange}
          />
          <label
            className="form-check-label text-xs"
            htmlFor="flexRadioDefault1"
          >
            I Agree with the 
            <a href="/TermsConditions" target="_blanck" rel="noreferrer" style={{textDecoration :"none"}}>
            Terms & Conditions
            </a>
          </label>
        </div>
        <hr className="mb-3" />
        <div className="text-center">
          <button
            className="btn-md hoverEffect px-3 py-2 rounded "
            // onClick={createSaleItems}
            onClick={onSubmitForm}
            disabled={!validateForm() || Loading}
            type="button"
          >
            {Loading ? (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                ></span>
                <span className="mx-2">Loading . . .</span>
              </>
            ) : (
              <>
                <i
                  className="bi bi-credit-card-2-front"
                  tw="px-3 text-base"
                ></i>
                Proceed to Pay
              </>
            )}
          </button>
        </div>
      </div>
    </form>
  );
}

export default memo(ChoosePaymentMethod)