import React from "react";
import Noimg from "../images/no_img.jpg";
import tw from "twin.macro";

// eslint-disable-next-line
const Heading = tw.h1`text-center`;

export default function OrderDetails({ viewCart, total }) {
  return (
    <div className="col-md-4  my-sm-0 my-4">
      <div className="max-w-md mx-auto bg-white shadow-lg rounded-lg md:max-w-xl mx-2">
        <div className="px-5" tw="py-2 border-b-4 border-custom-100">
          <b>Order Details</b>
        </div>
        <table className="table table-borderless m-0">
          <tbody>
            {viewCart.map((list, index) => (
              <>
              <tr className="border-bottom" key={index}>
                <td>
                  <td>
                    <img
                      // src={
                      //   list.productImageUrlList && list.productImageUrlList[0]
                      //     ? list.productImageUrlList[0]
                      //     : Noimg
                      // }
                      src={
                        list.images === "Image_Not_Available" || list.images === "-" || list.images === null || list.images ==="@#@"
                          ? Noimg
                          : list.images?.split("@#@")[0]
                      }
                      alt="Zinger Jewellers-product"
                      tw="w-20"
                    />
                  </td>
                  <td>
                    <div className="ms-4">
                      <span tw="text-sm m-0 block">{list.productName}</span>
                      <p tw="m-0 text-sm">
                        <i className="bi bi-tag-fill"></i> {list.productCode}
                      </p>
                      <small tw="text-xs text-custom-100">
                        {list.productTypeName}
                      </small>
                    </div>
                  </td>
                </td>
                <td>
                  <span className="product-price">
                  ₹{" "}
                    {/* ₹ {list.basePrice.toFixed(2)} */}
                    {/* {Math.trunc(
                      (list && Object.keys(list).length > 0 && list.basePrice) +
                      (list && Object.keys(list).length > 0 && list.diamondTotal) +
                      (list && Object.keys(list).length > 0 && list.platinumTotal) +
                      (list && Object.keys(list).length > 0 && list.secGoldTotal)
                        )} */}
                        {Math.trunc(list.total)}
                  </span>
                </td>
              </tr>
              </>
            ))}
          </tbody>
        </table>

        <div className="price-detail-section orderItem-box " tw="border border-custom-100 border-dashed">
          <p className="text-success mb-2 fw-bold">
            {/* Order Summary ( {viewCart.length} Items ) */}
            Order Summary ({viewCart.length}) {" "}{`${viewCart.length === 1 ? "Item" : "Items"}`}
          </p>
          {/* <div className="row">
            <div className="label-col col-sm-7 col">
              <p>Sub Total</p>
            </div>
            <div className="price-col col-sm-5 col">
              <p>
                <span>₹ </span>
                <span>
                  {total[3] && total[3].toFixed(2)}
                    ₹ {list.basePrice.toFixed(2)}
                    {(
                      (viewCart && viewCart.length > 0 && viewCart[0].basePrice) +
                      (viewCart && viewCart.length > 0 && viewCart[0].diamondTotal) +
                      (viewCart && viewCart.length > 0 && viewCart[0].platinumTotal) +
                      (viewCart && viewCart.length > 0 && viewCart[0].secGoldTotal)
                        ).toFixed(2)} 
  
                </span>
              </p>
            </div>
          </div> */}
          {/* <div className="row">
            <div className="label-col col-sm-7 col">
              <p>Tax  3%</p>
            </div>
            <div className="price-col col-sm-5 col">
              <p className="">
                <span>₹ </span>
                <span>
                  {total[1] && total[1].toFixed(2)}
                  {
                    (
                      ((viewCart?.[0]?.saleTaxList?.length > 0 && viewCart[0].saleTaxList[0]?.amount) || 0) +
                      ((viewCart?.[0]?.saleTaxList?.length > 1 && viewCart[0].saleTaxList[1]?.amount) || 0) +
                      (viewCart[0]?.secGoldTax ? parseFloat(viewCart[0].secGoldTax) : 0) +
                      (viewCart[0]?.diamondTax ? parseFloat(viewCart[0].diamondTax) : 0) +
                      (viewCart[0]?.platinumTax ? parseFloat(viewCart[0].platinumTax) : 0)
                    ).toFixed(2)
                  }
                  
                  </span>
              </p>
            </div>
          </div> */}
          {/* {viewCart && viewCart.length > 0  && viewCart[0].hallmarkCharge > 0 && (
             <div className="row">
            <div className="label-col col-sm-7 col">
              {viewCart && viewCart[0].hmTaxPercentage > 0 ?(
                 <p>Hallmark Charge with tax</p>):(<p>Hallmark Charge</p>)}
            </div>
            <div className="price-col col-sm-5 col">
              <p className="">
                <span>₹ </span>
                <span>
                  {(
                      viewCart[0].hallmarkCharge +
                      viewCart[0].hallmarkCharge * (viewCart[0].hmTaxPercentage / 100)
                    ).toFixed(2)}
                </span>
              </p>
            </div>
          </div> 
          )} */}
          <div className="row">
            <div className="label-col col-sm-7 col ">
              <span className="you-pay fw-bold "> Grand Total</span>
              <small className="inc-tax" tw="text-xs">
                {" "}
                {/* (Incl of all taxes.) */}
              </small>
            </div>
            <div className="price-col col-sm-5 col">
              <span className="you-pay-amt fw-bold text-uppercase">
                <span>₹ </span>
                {/* <span>{total[0] && Math.trunc(total[0])}</span> */}
                <span>
                  {Math.trunc(total)}
                  </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
