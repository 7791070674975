import tw from "twin.macro";
import styled from "styled-components";
import { schemeType } from "../api/NewSchemeApi";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg/svg-decorator-blob-7.svg";
import Noimg from "../images/no_img.jpg";
import icon1 from "images/SavingScheme/1.png";
import icon2 from "images/SavingScheme/2.png";
import icon3 from "images/SavingScheme/3.png";
import { dataList } from "api/SkeletonData";
import Skeleton from "react-loading-skeleton";
import { getCustomerId } from "utility/HeaderConfig";
import { useCartContext } from "context/UseCartContext";

const Container = tw.div`relative font-display overflow-hidden`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-custom-100`}
`;

export default ({ branch }) => {
  const [Scheme, setScheme] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [banner, setBanner] = useState([]);
  const [isReadMore, setIsReadMore] = useState(false);

  const { dispatch } = useCartContext();

  useEffect(() => {
    getSchemeType();
    // getBanner();
    // eslint-disable-next-line
  }, []);

  // const getBanner = () => {
  //   configImages &&
  //     configImages.E_COMMERCE_BANNERS.forEach((data) => {
  //       if (data.key === "savingScheme") {
  //         setBanner(data.value);
  //       }
  //     });
  // };

  const history = useHistory();

  const getSchemeType = () => {
    setLoading(true);
    let filter = {
      visibilityStatus: "Mobile"
    };
    schemeType(filter).then(
      (data) => {
        setScheme(data.response.content);
        setLoading(false);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const toggleReadMore = (id) => {
    setIsReadMore(id);
  };

  const checkLogin = () => {
    if (getCustomerId()) {
      history.push("/MyScheme");
    } else {
      dispatch({ type: "MODAL", payload: "login" });
    }
  };

  // console.log("my sheme data", Scheme);
  return (
    <Container>
      <section tw="pb-10 relative" className="custom_background">
        <div className="w-100 text-white " tw="bg-custom-100">
          <div className="container py-4">
            <h2 className="text-center text-uppercase">
              Benefits of{" "}
              <span className="text-warning">{branch.storeName}</span> Saving
              Scheme
            </h2>
            <div className="row py-4">
            <div className="col-md-6 col-xl-4">
                <img
                  src={icon1}
                  alt="Jewellery Saving Scheme"
                  className="m-auto py-3 spinner"
                />
                <h4 className="text-center" tw="text-xl">
                  Easy Future
                </h4>
                <p className="text-center" tw="text-sm">
                  Boon to middle class people. Special help to all class people.
                </p>
              </div>
              <div className="col-md-6 col-xl-4 position-relative">
                {/* <img
                  src={icon3}
                  alt="Zinger Jewellers saving scheme"
                  className="m-auto py-3 spinner"
                />
                <h4 className="text-center" tw="text-xl">
                  Your Money is Safe
                </h4>
                <p className="text-center" tw="text-sm">
                  The money which is saved guaranteed for Ornament Purchase.
                </p> */}
                <div tw="p-5 bg-white	absolute rounded-xl border border-dotted border-custom-200 shadow-2xl">
                  <img
                    src={icon3}
                    alt="Zinger Jewellers saving scheme"
                    className="m-auto py-3 spinner"
                  />
                  <h4
                    className="text-center text-black"
                    tw="text-xl font-bold"
                  >
                   Special Discount
                  </h4>
                 
                  <a
                    href="#join"
                    tw="rounded-lg px-3 py-2 w-full bg-custom-100 uppercase my-4 text-white text-center hover:text-white no-underline inline-block"
                  >
                    JOIN NEW SavingScheme
                  </a>
                  <button
                    type="button"
                    tw="px-3 py-2 w-full bg-custom-green uppercase rounded-md"
                    onClick={checkLogin}
                  >
                    PAY YOUR MONTHLY SCHEME
                  </button>
                </div>
              </div>
              <div className="col-md-6 col-xl-4">
                <img
                  src={icon2}
                  alt="Zinger Jewellers saving scheme"
                  className="m-auto py-3 spinner"
                />
                <h4 className="text-center" tw="text-xl">
                Your Money is Safe
                </h4>
                <p className="text-center" tw="text-sm">
                  The savings gold amount fetch admirable bonus, which is more
                  valuable than special discount.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container container-influid-md py-5" id="join">
          <div className="row ">
            {Scheme.map((item) => (
              <div className="col-md-6 col-xl-4 py-3">
                <div
                  className=" border"
                  tw="hover:shadow-lg bg-custom-200 rounded-xl"
                >
                  <img
                    src={
                      item.chitImage !== null &&
                      item.chitImage !== "" &&
                      item.chitImage !== "-" &&
                      item.chitImage !== "Image_Not_Available"
                        ? item.chitImage
                        : Noimg
                    }
                    alt="schemeImage"
                    className=" m-auto my-2"
                    tw="rounded-2xl shadow-xl"
                    style={{ height: "261px", width: "96%" }}
                  />
                  <div className="p-3">
                    <h6 tw="text-base text-custom-100 mb-2 uppercase">
                      {item.name} ({item.type})
                    </h6>
                    <p className="mb-1" tw="text-sm">
                      Description
                    </p>
                    <p tw="mb-2 text-xs">
                      {item.description !== ""
                        ? isReadMore === item.id
                          ? item.description
                          : item.description.slice(0, 150)
                        : "No Description"}
                      {item.description !== "" &&
                      item.description.length > 150 ? (
                        isReadMore === item.id ? (
                          <span
                            tw="cursor-pointer font-bold hover:text-custom-100"
                            onClick={() => toggleReadMore(false)}
                          >
                            ....Show Less <i className="bi bi-caret-up"></i>
                          </span>
                        ) : (
                          <span
                            tw="cursor-pointer font-bold hover:text-blue-700"
                            onClick={() => toggleReadMore(item.id)}
                          >
                            ....Read More <i className="bi bi-caret-down"></i>
                          </span>
                        )
                      ) : (
                        ""
                      )}
                    </p>

                    <button
                      className="px-4 py-2 text-white  tracking-wider  mt-2 "
                      style={{ transition: "width 1s" }}
                      tw="text-base bg-custom-100 sm:w-3/4 w-full rounded-lg transition-all hover:w-full font-semibold uppercase"
                      onClick={() => history.push("/SchemeDetails", item.id)}
                    >
                      View Scheme Details{" "}
                      <i className="bi bi-arrow-right-circle mx-3"></i>
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {loading && (
            <div className="row justify-content-center">
              {dataList.slice(0, 8).map((data) => (
                <div className="col-md-4 py-3">
                  <Skeleton tw="w-full" style={{ height: "300px" }} />
                  <div className="card my-2 text-center py-2">
                    <Skeleton className="px-3" tw="w-11/12" />
                    <Skeleton className="px-3" tw="w-11/12" />
                    <Skeleton className="px-3" tw="w-11/12" />
                    <Skeleton className="px-3" tw="w-11/12" />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <DecoratorBlob1 />
        <DecoratorBlob2 />
      </section>
    </Container>
  );
};
