import React, { useEffect, useState } from "react";
import Noimg from "../images/no_img.jpg";
import styled from "styled-components";
import tw from "twin.macro";
import { dateMonthYearFormatUtcToLocal } from "../utility/Constant";
import no_data from "images/no_data.png";
import { css } from "styled-components/macro";
import { getCustomerId } from "../utility/HeaderConfig";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg/svg-decorator-blob-5.svg";
import { getMyPurchaseData } from "api/OffersApi";
import moment from "moment";
import { downloadFile, invoiceApi } from "api/TrackingApi";


const Container = tw.div`relative font-display overflow-hidden`;
const Heading = tw.h1`text-center font-dancing block text-black  text-5xl m-0 text-white`;
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-custom-100`}
`;
const BannerImg = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`bg-center bg-cover relative rounded-t w-full py-24 bg-center`}
`;


function MyPurchase({configImages}) {
    
 const [offers, setOffers] = useState([]);
 const [loading, setLoading] = useState(true);
 const [banner, setBanner] = useState([]);
const [invoiceData,setInvoiceData] =useState([]);
useEffect(() => {
getBanner();
getMyPurchase();

//eslint-disable-next-line
}, []);


const getMyPurchase = () => {

let customerId = getCustomerId();
let currentdate = moment();
let toDate = currentdate;
let toDateMoment = moment(toDate);
let fromDate = toDateMoment.clone().subtract(6, "months").subtract(1,"days").set({ hour: 18, minute: 30, second: 0 }).format("YYYY-MM-DDTHH:mm:ss[Z]");

 setLoading(true);
 getMyPurchaseData(fromDate,toDate,customerId).then(
   (data) => {
    const responseData = [];
    data.response.forEach((item)=>{
      if(item.trackingDetails !== ""){
        item.trackingDetails =JSON.parse(item.trackingDetails)
        responseData.push(item);
      }else{
        responseData.push(item);
      }
    })
    setOffers(responseData)
     setLoading(false);
   },
   (err) => {
     console.log(err);
   }
 );
  };

const getInvoice =  (saleId) =>{
  const salesInvoiceData = invoiceApi(saleId)
  salesInvoiceData.then((data) => {
    downloadFile(data.response,"Invoice.pdf")
   setInvoiceData(data.response)
  //  return data.response
  }).catch(err => console.error(err));
}

 const getBanner = () => {
        configImages && configImages.E_COMMERCE_BANNERS.forEach((data) => {
          if (data.key === "offer") {
            setBanner(data.value);
          }
        });
      };      

    return(
        <>
        <Container>
        <BannerImg tw="flex justify-center" imageSrc={banner}>
          <Heading>My Purchase</Heading>
        </BannerImg>
        <div className="ptSans px-sm-5 px-0" tw="text-sm pt-3">
          <Link to="/" tw="mx-3 no-underline text-black">
            Home
          </Link>
          /<span tw="ml-3 text-custom-100 capitalize tracking-wide ">My Purchase</span>
        </div>
        <section tw="py-10 relative">
          <div className="container-md">

            {offers.length === 0 && !loading ? (
              <img src={no_data} alt="no-data" className="col-md-5 m-auto" />
            ) : (
              <div className="row align-items-center">
                {offers.map((item) => (
                  <>
                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-4 py-3">
                    <div className="rounded" tw="hover:shadow-2xl">
                        <>
                      <img
                        src={
                          item.productImageUrl[0]  === null || item.productImageUrl[0]  === "" || item.productImageUrl[0] === "-"  
                          ? Noimg : item.productImageUrl[0]
                        }
                        alt="schemeImage"
                        className="w-100"
                        style={{ height: "261px" }}
                      />
                      </>
                      <div
                        className="box bg-light position-relative mw-100"
                        tw="top-0 left-0 translate-x-0 transform-none "
                      >
                        <div className="box-inner p-0">
                          <div className="p-3" tw="bg-gray-100 ">
                            <div className="row align-items-center ">
                              <div className="col-6">
                                <h6 tw="text-lg text-custom-100 mb-2 uppercase">
                                  {item.productName} {item.productTagNo} <br/>₹{Math.trunc(item.saleItemTotal)}
                                  <br/>
                                  {item.saleNo}
                                </h6>
                              </div>
                            </div>
                          <div className="row">
                          <div className="col">
                            Booked Date : {dateMonthYearFormatUtcToLocal(item.createdAt)}
                            </div> 
                          <div className="row">
                           <div className="col">
                           Delivery Status : {item.saleItemDeliveryStatus.toLowerCase() === "no"
                              ? "Non-Delivered"
                              : item.saleItemDeliveryStatus.toLowerCase() === "yes"
                                ? "Delivered"
                                : "-"}
                           </div>
                          </div>
                          {item.saleItemDeliveryStatus.toLowerCase() === "no" ? null : 
                          <div className="row">
                           <div className="col">
                           Delivered Date : {moment(item.deliveryDate ? item.deliveryDate : item.deliveryDate === "Invalid date" ? "-" : item.deliveryDate).format("YYYY-MM-DD")}
                           </div>
                          </div>
                           }
                          <div className="row">
                           <div className="col">
                           Address : {item.address1?item.address1:"-"},{item.address2?item.address2:"-"}
                           </div>
                          </div>
                          <div className="row">
                           <div className="col">
                           Landmark : {item.landmark? item.landmark : "-" }
                           </div>
                          </div>
                          <div className="row">
                           <div className="col">
                           Vendor Name : {item.trackingDetails.vendorName ? item.trackingDetails.vendorName:"-"}
                           </div>
                          </div>
                          <div className="row">
                           <div className="col">
                           Tracking Details : {item.trackingDetails.trackingDetails?item.trackingDetails.trackingDetails:"-"}
                           </div>
                          </div>
                          <a className="btn btn-outline-success w-50" href={item.trackingDetails.trackingUrl} role="button">Track Here</a>
                          {/* <a className="btn btn-outline-success w-50"  onClick={()=>getInvoice(item.saleId)} role="button" >Invoice</a> */}
                          <button className="btn btn-outline-success w-50" onClick={()=> getInvoice(item.saleId)}> Invoice</button>
                          </div>                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </>
                ))}
              </div>
            )}
          </div>
          <DecoratorBlob1 />
          <DecoratorBlob2 />
        </section>
      </Container>
      </>
    )
}

export default MyPurchase