import React, { useEffect, useState } from 'react';
import tw from "twin.macro";
import Skeleton from "react-loading-skeleton";
import {Link}from "react-router-dom";
import Reffer from '../images/reffer_img.jpg'
import {  getReferralPoints } from 'api/OffersApi';
import { getCustomerId } from 'utility/HeaderConfig';

const Container = tw.div`relative font-display overflow-hidden`;



function ReferralPoints({configImages}) {
   
  const [banner, setBanner] = useState([]);
  //eslint-disable-next-line
  const [referralPoints, setReferralPoints] = useState([]);
  const [totalPoints, setTotalPoints] = useState(null);
  const [totalValue, setTotalValue] = useState(null);
  
  useEffect(()=>{
    getBanner();
    initialFunction();
    // eslint-disable-next-line
  },[])

  const initialFunction =  async() => {
    let customerId = getCustomerId();
    let refId = customerId;
    const referralPointsRes =  await getReferralPoints(refId);
    const respData = referralPointsRes.response;
    setReferralPoints(respData);
    const totalPoints = respData.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.referralPoints;
    }, 0);
    setTotalPoints(totalPoints);
    const totalValue = respData.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.referralValue;
    }, 0);
    setTotalValue(totalValue);
  }

  const getBanner = () => {
      configImages &&
        configImages.E_COMMERCE_BANNERS.forEach((data) => {
          if (data.key === "referral") {
            setBanner(data.value);
          }
        });
    };

  return (
    <Container>
        {banner && banner.length > 0 ? (
        <img src={banner} alt="New Arrivals Products" className="w-100" />
      ) : (
        <Skeleton tw="w-full" style={{ height: "200px" }} />
      )}
       <div className="ptSans px-sm-5 px-0" tw="text-sm pt-3">
        <Link to="/" tw="mx-3 no-underline text-black">
          Home
        </Link>
        /
        <span tw="ml-3 text-custom-100 capitalize tracking-wide ">
        Referral Points
        </span>
      </div>
      <div className="py-sm-5  pb-2 mb-5 position-relative">
        <div className="container container-influid-md">
            <div className='row'>
                <div className='col mt-5'>
                <h1 className='getreffer'>Get Reffer And Earn</h1>
                 <h4 className='totalaval'>TOAL AVAILABLE POINTS</h4>
                 <div class="shadow p-3 mb-5 bg-white rounded">
                  <p className="points">{totalPoints !== null || totalPoints !== undefined || totalPoints !== '' ? `${Math.ceil(totalPoints)} Points` : `0 Points`}</p>
                </div>
                  <p className='totalreferral'> your total referral points is worth ₹ {Math.round(totalValue)}</p>
                </div>
                <div className='col'>                
                <img src={Reffer} alt='refferimage'/>
                </div>
            </div>
        </div>
        </div>
    </Container>
  )
}

export default ReferralPoints