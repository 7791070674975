import { getProductCate } from 'api/ProductCategoryApi';
import React, { useEffect, useState } from 'react';
import tw from "twin.macro";
import Noimg from "../images/no_img.jpg";
import no_data from "images/no_data.png";
import { motion } from "framer-motion";
import Carousel from "react-elastic-carousel";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

const CardContainer = tw.div`mt-4 w-full`;
const Card = tw(
  motion.a
)`rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0 no-underline`;
const CardImageContainer = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`bg-center bg-cover relative rounded `}
`;

function SecondBanner() {

  const [bannerPositionOne, setBannerPositionOne] = useState([]);

  useEffect(() => {
    getBannerList();
    //eslint-disable-next-line
  }, []);

  const getBannerList = () => {

  getProductCate({
    visibilityMode : 'POSITION_1'
  })
  .then((data) =>{
    setBannerPositionOne(data.response.content)
  })
  .catch((err) => {
    console.error(err)
  })

   }

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    // { width: 768, itemsToShow: 3 },
    // { width: 900, itemsToShow: 4 },
  ];

  return (
    <>
  <div className="container">
    {bannerPositionOne.length === 0 ? ( 
 <img src={no_data} alt="no-data" className="col-md-4 m-auto" />
     )  
    : (
      <Carousel
        itemPadding={[0, 10]}
        itemsToShow={1}
        breakPoints={breakPoints}
        className="newArrivalsComponent"
      >
        {bannerPositionOne.map((categoryItem, index) => ( 
           <div className="w-100" key={index}>
            <CardContainer
              tw="cursor-pointer relative"
            >
              <Card className="group position-relative ">
                <div className="overflow-hidden">
                  <CardImageContainer
                    className="group" 
                    style={{height:'369px'}}
                    tw="md:h-96 border border-gray-300 rounded-t-lg flex justify-center w-full  transform transition duration-500  group-hover:(scale-125 rotate-12)"
                    imageSrc={
                      categoryItem.imageUrl === "Image_Not_Available" || categoryItem.imageUrl === "-" || categoryItem.imageUrl === null 
                        ? Noimg
                        : categoryItem.imageUrl
                    }
                  ></CardImageContainer>
                </div>
              </Card>
            </CardContainer>
          </div> 
         ))}
      </Carousel>
    )} 
  </div>
    </>
  )
}

export default SecondBanner